import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import "../../css/campaign-report/add-post.css";
import AddPostDialog from './add-post-dialog';
import heartIcon from '../../assets/heart-icon.svg';
import commentIcon from '../../assets/comment-icon.svg';
import viewIcon from '../../assets/view-icon.svg';
import crossIcon from '../../assets/cross-icon.svg';
import instaIcon from '../../assets/instaIcon.svg';

import { getConfig } from '../common/env-config';
import { formatNumber, formatPostDate } from './date-utils';
import RemovePostDialog from './remove-post-dialog';
import { getCampaignReportPosts, removePostFromCampaignReport } from './report-repository';
import defaultProfile from '../../assets/profile.svg';

import Alert from '../common/alert';



function AddPost({ reportId , reportData, setReportData, loading, setLoading  }) {
    const [openDialog, setOpenDialog] = useState(false);
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { baseUrl } = getConfig();

    const fetchPosts = useCallback(async () => {
        try {
            const response = await getCampaignReportPosts(reportId, null, null, setLoading);
            if (response.success) {
                setReportData((prevData) => ({
                    ...prevData,
                    campaignPosts: response.data.campaignPosts || [],
                    totalPosts: response.data.totalPosts || 0,
                }));
            } else {
                console.error("Failed to fetch posts:", response.message);
            }
        } catch (error) {
            console.error("Error fetching posts:", error);
        }
    }, [reportId, setLoading, setReportData]);
    
    useEffect(() => {
        fetchPosts();
    }, [fetchPosts]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleOpenRemoveDialog = (postId) => {
        setSelectedPostId(postId);
        setOpenRemoveDialog(true);
    };

    const handleCloseRemoveDialog = () => {
        setOpenRemoveDialog(false);
        setSelectedPostId(null);
    };
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
        fetchPosts();
    };

    const handleRemovePost = async () => {
        if (!selectedPostId) return;

        try {
            const response = await removePostFromCampaignReport(reportId, selectedPostId);
            if (response.success) {
                setReportData((prevData) => ({
                    ...prevData,
                    campaignPosts: prevData.campaignPosts.filter(post => post.postId !== selectedPostId),
                }));
                setSnackbarMessage("Post link removed successfully.");
                setOpenSnackbar(true);
                window.location.reload();    
            } else {
                console.error("Failed to remove post:", response.message);
            }
        } catch (error) {
            console.error("Error removing post:", error);
        } finally {
            handleCloseRemoveDialog();
        }
    };
    return (
        <>
            <div className='totalpost-container'>
                <div className='total-post'>
                    <div className='totalpost-text'>Total Post</div>
                    <div className='post-count'>{reportData?.totalPosts || 0} Post</div>
                </div>
                <div className='ec-leads-top-filter' style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#65308F',
                            '&:hover': { backgroundColor: '#65308F' },
                            borderRadius: "50px",
                        }}
                        onClick={handleOpenDialog}
                        startIcon={<AddIcon />}
                    >
                        Add link
                    </Button>
                </div>
            </div>
            <AddPostDialog open={openDialog} onClose={handleCloseDialog} reportId={reportId} setReportData={setReportData} setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} loading={loading} setLoading={setLoading} />



            <TableContainer component={Paper} style={{ boxShadow: 'none', border: 'none' }}>
                <Table aria-label="post data table" style={{ borderCollapse: 'collapse' }}>
                    <TableHead>
                        <TableRow style={{ borderBottom: '1px solid #434343' }}>
                            <TableCell style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: '400', color: '#434343' }}>Posted on</TableCell>
                            <TableCell style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: '400', color: '#434343' }}>Like</TableCell>
                            <TableCell style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: '400', color: '#434343' }}>Comments</TableCell>
                            <TableCell style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: '400', color: '#434343' }}>Views</TableCell>
                            <TableCell style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: '400', color: '#434343' }}>Impression</TableCell>
                            <TableCell style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: '400', color: '#434343' }}>Share</TableCell>
                            <TableCell style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: '400', color: '#434343' }}>Eng. rate</TableCell>
                            <TableCell style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: '400', color: '#434343' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData?.campaignPosts ? (
                            reportData.campaignPosts.length > 0 ? (
                                reportData.campaignPosts.map((post) => (
                                    <TableRow key={post.postId} style={{ borderBottom: '1px solid #AEAEAE' }}>
                                        <TableCell style={{ width: '250px' }}>
                                        <a href={post.postLink} target="_blank" rel="noopener noreferrer" className="user-profile" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                                        {post.postId ? (
                                                    <img
                                                        src={`${baseUrl}/download-insta-profile/${post.postMediaId}`}
                                                        alt="Profile"
                                                        className="profile-image"
                                                    />
                                                ) : (
                                                    <img
                                                            src={defaultProfile}
                                                            alt=""
                                                            style={{ height: '50px', width: '50px'}}
                                                        />
                                                )}

                                                <div className='profile-container'>
                                                    <div className='insta-handle-container'>
                                                        <img src={instaIcon} alt="insta" style={{ width: '16px', marginRight: '5px' }} />
                                                        <Tooltip title={post.profile?.instaHandle || "-"} arrow>
                                                            <p style={{ margin: 0, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '130px', cursor: 'pointer' }}>
                                                                @{post.profile?.instaHandle || "-"}
                                                            </p>
                                                        </Tooltip>
                                                    </div>
                                                    <p>{formatPostDate(post.postTime) || "-"}</p>
                                                </div>
                                            </a>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <img src={heartIcon} alt="likes" style={{ width: '20px', marginRight: '5px' }} />
                                            {formatNumber(post.likes || 0)}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <img src={commentIcon} alt="comments" style={{ width: '20px', marginRight: '5px' }} />
                                            {formatNumber(post.comments || 0)}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <img src={viewIcon} alt="views" style={{ width: '20px', marginRight: '5px' }} />
                                            {formatNumber(post.views || 0)}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{formatNumber(post.impressions === "Infinity" || isNaN(post.impressions) ? '-' : post.impressions || '-')}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{formatNumber(post.share === "Infinity" || isNaN(post.share) ? '-' : post.share || '-')}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            {post.engagementRate === "Infinity" || isNaN(post.engagementRate) ? '-' : `${parseFloat(post.engagementRate).toFixed(2)}%`}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <img src={crossIcon} alt="delete" style={{ width: '20px', marginRight: '5px', cursor: 'pointer' }} onClick={() => handleOpenRemoveDialog(post.postId)} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} align="center" style={{ borderBottom: '1px solid #AEAEAE' }}>No posts found</TableCell>
                                </TableRow>
                            )
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} align="center" style={{ borderBottom: '1px solid #AEAEAE' }}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>

                </Table>
            </TableContainer>
            <RemovePostDialog
                open={openRemoveDialog}
                onClose={handleCloseRemoveDialog}
                onConfirm={handleRemovePost}
            />
            <Alert open={openSnackbar} severity="success" message={snackbarMessage} onClose={handleSnackbarClose} />

        </>
    );
}

export default AddPost;


