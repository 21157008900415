import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import greenTick from '../../assets/green-tick.svg';

function SuccessDialog({ open, message }) {
  return (
    <Dialog open={open}>
      <DialogContent>
        <div className="success-dialog">
          <div className="green-tick">
            <img className='greentick-img' src={greenTick} alt='' />
          </div>
          <div>{message}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SuccessDialog;
