import React, { useState } from 'react';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import "../../css/campaign-report/add-post.css";
import linkIcon from '../../assets/link-icon.svg';
import { addPostToCampaignReport, getCampaignReportPosts } from './report-repository';

function AddPostDialog({ open, onClose, reportId, setReportData, setSnackbarMessage, setOpenSnackbar,  loading, setLoading }) {
    const [postLink, setPostLink] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleSave = async () => {
        if (!postLink.trim()) {
            setErrorMessage("Please enter a valid post link.");
            return;
        }
    
        setLoading(true);
    
        try {
            const result = await addPostToCampaignReport(reportId, postLink);
    
            if (result.success) {
                setPostLink("");
                setErrorMessage("");
                onClose();
    
                setSnackbarMessage("Link added successfully.");
                setOpenSnackbar(true);
    
                setTimeout(async () => {
                    const updatedPostsResult = await getCampaignReportPosts(reportId,null, null, setLoading);
                    console.log("setLoading",loading);
                    if (updatedPostsResult.success) {
                        setReportData(prevData => ({
                            ...prevData,
                            campaignPosts: updatedPostsResult.data,
                            totalPosts: updatedPostsResult.data.length,
                        }));
                    } else {
                        console.error("Failed to fetch updated posts:", updatedPostsResult.message);
                    }
                }, 500); 
                window.location.reload()
            } else {
                setErrorMessage(result.message);
            }
        } catch (error) {
            console.error(error);
            setSnackbarMessage("Post added, but failed to update the list.");
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    

    const handleDiscard = () => {
        setPostLink("");  
        setErrorMessage(""); 
        onClose(); 
    };

    return (
        <Dialog open={open} onClose={handleDiscard}>
            <div className='addpost-dialog'>
                <DialogContent>
                    <div className='title-align'>
                        <img className="link-img" src={linkIcon} alt="icon" />
                        <DialogTitle>Add post link</DialogTitle>
                    </div>
                    <TextField
                        autoFocus
                        margin="dense"
                        type="url"
                        fullWidth
                        variant="outlined"
                        required
                        placeholder='Paste post link (URL) from Instagram'
                        value={postLink}
                        onChange={(e) => setPostLink(e.target.value)}
                        style={{ width: '391px' }}
                    />
                    {errorMessage && <p style={{ color: "red", fontSize: "14px" }}>{errorMessage}</p>}
                </DialogContent>
                <DialogActions>
                    <footer style={{ display: "flex", gap: '24px' }}>
                        <button type="button" className="button button--secondary" onClick={handleDiscard}>
                            Discard
                        </button>
                        <button
                            type="button"
                            className="button button--primary"
                            onClick={handleSave}
                            disabled={loading} 
                        >
                            {loading ? <CircularProgress size={20} color="inherit" /> : "Save"}  
                        </button>
                    </footer>
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default AddPostDialog;
