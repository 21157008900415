import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getConfig } from '../common/env-config';
import { useNavigate } from 'react-router-dom';
import { getCampaignsList } from '../campaign/campaign-repository';
import moment from "moment";
import { UserDetailsContext, WindowWidthContext } from '../context/user-details-context';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function TopCampaign() {
    const navigate = useNavigate();
    const windowWidth = useContext(WindowWidthContext);
    const { baseUrl } = getConfig();
    const { loggedUser } = useContext(UserDetailsContext);
    const [campaignList, setCampaignList] = useState({ campaigns: [] });
    const [loading, setLoading] = useState(true);
    const date = new Date();
    const currentDate = date.toISOString().slice(0, 10);

    useEffect(() => {
        // eslint-disable-next-line
        getCampaignsList('/all-campaigns', setCampaignList, setLoading, 1, 5);
        // eslint-disable-next-line
    }, []);

    const campaignColumns = [
        { id: 1, label: 'Campaign Name', minWidth: windowWidth <= 799 ? 180 : 320 },
        { id: 2, label: 'City', minWidth: windowWidth <= 799 ? 180 : 200 },
        { id: 3, label: 'Date of Submission', minWidth: windowWidth <= 799 ? 180 : 200 },
        { id: 4, label: 'Project Type', minWidth: 180 },
        { id: 5, label: '', minWidth: 180 },
    ];
    if (windowWidth < 799) {
        campaignColumns.splice(1, 0, { id: 2, label: 'Apply Campaign', minWidth: 180 });
        const index = campaignColumns.findIndex(column => column.id === 5);
        if (index !== -1) {
            campaignColumns.splice(index, 1);
        }
    }

    const handleViewAll = () => {
        navigate('/all-campaigns/live');
    }

    const handleCampaignClick = (campaign) => {
        navigate('/all-campaigns/details', { state: { campaignid: campaign.campaignId } });
    };

    return (
        <Box sx={{ width: windowWidth <= 799 ? '100vw' : '100%' }}>
            <Paper sx={{ width: windowWidth <= 799 ? '100vw' : '100%', mb: 2, backgroundColor: '#f4f5f8', padding: windowWidth <= 799 ? '26px 29px 26px 0px' : '26px 29px 26px 29px', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <div className='top-campaign-text'>Top 5 Campaigns</div>
                <TableContainer sx={{ overflowX: 'auto', flexGrow: 1, scrollbarWidth: 'none', '-ms-overflow-style': 'none', padding: '0px 11px 0px 11px' }}>
                    <Table className='top-campaign-table' aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {campaignColumns.map((column) => (
                                    <TableCell sx={{ backgroundColor: 'transparent', fontFamily: 'DM Sans', color: '#7D8FA9', opacity: 1, fontSize: '15px', fontWeight: 600 }} key={column.id}
                                        style={{ minWidth: column.minWidth }}>
                                        <div className='top-campaign-tooltip'>
                                            {column.label}
                                            {column.tooltip && <Tooltip title={column.tooltip}>
                                                <InfoOutlinedIcon sx={{ m: 1 }} />
                                            </Tooltip>}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                campaignList.campaigns.map((campaign) => (
                                    <TableRow key={campaign.campaignId} className='top-campaign-row'>
                                        <TableCell className='top-creator-name'>
                                            <div className='campaign-name-column'>
                                                {loading && !campaign ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <div className="_img_19x11_1 _avatar_1ig5e_24" style={{
                                                        backgroundImage: `url(${campaign.brandHandleProfileId
                                                            ? `${baseUrl}/download-insta-profile/${campaign.brandHandleProfileId}`
                                                            : <svg width="16" height="16" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="_verified_dbbth_91">
                                                                <path d="M22.5 11L20.06 8.21L20.4 4.52L16.79 3.7L14.9 0.5L11.5 1.96L8.1 0.5L6.21 3.69L2.6 4.5L2.94 8.2L0.5 11L2.94 13.79L2.6 17.49L6.21 18.31L8.1 21.5L11.5 20.03L14.9 21.49L16.79 18.3L20.4 17.48L20.06 13.79L22.5 11ZM8.88 15.01L6.5 12.61C6.11 12.22 6.11 11.59 6.5 11.2L6.57 11.13C6.96 10.74 7.6 10.74 7.99 11.13L9.6 12.75L14.75 7.59C15.14 7.2 15.78 7.2 16.17 7.59L16.24 7.66C16.63 8.05 16.63 8.68 16.24 9.07L10.32 15.01C9.91 15.4 9.28 15.4 8.88 15.01Z" fill="#4AABED">
                                                                </path>
                                                            </svg>
                                                            })`
                                                    }}
                                                    >
                                                    </div>
                                                )}
                                                <div className='top-creator-text'>
                                                    <div className='top-campaign-name' style={{ maxWidth: '15ch', overflowWrap: 'anywhere' }}>{campaign.campaignName}</div>
                                                    <div className='top-campaign-category'>{campaign.categoryTypes[0]}</div>
                                                </div>
                                            </div>
                                        </TableCell>
                                        {windowWidth <= 799 &&
                                            <TableCell>
                                                {campaign.campaignOwner.id !== (loggedUser && loggedUser.userId) && campaign.lastDate >= currentDate
                                                    ? (
                                                        <Button variant="contained" sx={{
                                                            width: '128px',
                                                            backgroundColor: campaign.campaignApplicants != null ?
                                                                campaign.campaignApplicants.some(applicant =>
                                                                    applicant.appliedStatus === 'Apply' &&
                                                                    applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                ) ? '#fff' : '#e563a4' : '#e563a4',
                                                            color: campaign.campaignApplicants != null ?
                                                                campaign.campaignApplicants.some(applicant =>
                                                                    applicant.appliedStatus === 'Apply' &&
                                                                    applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                ) ? '#5d5c5c' : '#fff' : '#fff',
                                                            border: campaign.campaignApplicants != null ?
                                                                campaign.campaignApplicants.some(applicant =>
                                                                    applicant.appliedStatus === 'Apply' &&
                                                                    applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                ) ? '1px solid #928e8e' : 'none' : 'none',
                                                            textTransform: 'capitalize',
                                                            '&:hover': {
                                                                backgroundColor: campaign.campaignApplicants != null ?
                                                                    campaign.campaignApplicants.some(applicant =>
                                                                        applicant.appliedStatus === 'Apply' &&
                                                                        applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                    ) ? 'black' : '#e326a4' : '#e326a4',
                                                                color: '#fff'
                                                            }
                                                        }} onClick={() => { handleCampaignClick(campaign) }}
                                                            endIcon={<ArrowForwardIcon />}>
                                                            {campaign.campaignApplicants != null ?
                                                                campaign.campaignApplicants.some(applicant =>
                                                                    applicant.appliedStatus === 'Apply' &&
                                                                    applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                ) ? 'Withdraw' : 'Apply Now' : 'Apply Now'}
                                                        </Button>
                                                    )
                                                    : (
                                                        <Button variant="contained" disabled={true} sx={{
                                                            textTransform: 'capitalize', '&:disabled': {
                                                                backgroundColor: '#FFFFFF', color: '#586a84',
                                                                opacity: 0.3, border: '0.7868627309799194px solid #586A84'
                                                            }
                                                        }} endIcon={<ArrowForwardIcon />}>Apply Now</Button>
                                                    )
                                                }
                                            </TableCell>
                                        }
                                        <TableCell>
                                            <div className='top-campaign-row-text'>{campaign.campaignLocations != null && campaign.campaignLocations[0]}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='top-campaign-row-text'>{moment(campaign.lastDate).format("l")}</div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='top-campaign-row-text'>{campaign.projectType?.projectType}</div>
                                        </TableCell>
                                        {windowWidth >= 799 &&

                                            <TableCell>
                                                {campaign.campaignOwner.id !== (loggedUser && loggedUser.userId) && campaign.lastDate >= currentDate
                                                    ? (
                                                        <Button variant="contained" sx={{
                                                            width: '128px',
                                                            backgroundColor: campaign.campaignApplicants != null ?
                                                                campaign.campaignApplicants.some(applicant =>
                                                                    applicant.appliedStatus === 'Apply' &&
                                                                    applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                ) ? '#fff' : '#e563a4' : '#e563a4',
                                                            color: campaign.campaignApplicants != null ?
                                                                campaign.campaignApplicants.some(applicant =>
                                                                    applicant.appliedStatus === 'Apply' &&
                                                                    applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                ) ? '#5d5c5c' : '#fff' : '#fff',
                                                            border: campaign.campaignApplicants != null ?
                                                                campaign.campaignApplicants.some(applicant =>
                                                                    applicant.appliedStatus === 'Apply' &&
                                                                    applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                ) ? '1px solid #928e8e' : 'none' : 'none',
                                                            textTransform: 'capitalize',
                                                            '&:hover': {
                                                                backgroundColor: campaign.campaignApplicants != null ?
                                                                    campaign.campaignApplicants.some(applicant =>
                                                                        applicant.appliedStatus === 'Apply' &&
                                                                        applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                    ) ? 'black' : '#e326a4' : '#e326a4',
                                                                color: '#fff'
                                                            }
                                                        }} onClick={() => { handleCampaignClick(campaign) }}
                                                            endIcon={<ArrowForwardIcon />}>
                                                            {campaign.campaignApplicants != null ?
                                                                campaign.campaignApplicants.some(applicant =>
                                                                    applicant.appliedStatus === 'Apply' &&
                                                                    applicant.creatorId === (loggedUser && loggedUser.userId)
                                                                ) ? 'Withdraw' : 'Apply Now' : 'Apply Now'}
                                                        </Button>
                                                    )
                                                    : (
                                                        <Button variant="contained" disabled={true} sx={{
                                                            textTransform: 'capitalize', '&:disabled': {
                                                                backgroundColor: '#FFFFFF', color: '#586a84',
                                                                opacity: 0.3, border: '0.7868627309799194px solid #586A84'
                                                            }
                                                        }} endIcon={<ArrowForwardIcon />}>Apply Now</Button>
                                                    )
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                )))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='view-all-button'>
                    <Button variant="contained" sx={{
                        backgroundColor: '#FFFFFF', color: '#586a84', border: '0.75px solid #586A84',
                        textTransform: 'capitalize', '&:hover': {
                            backgroundColor: 'black', color: '#FFFFFF'
                        }
                    }} onClick={() => handleViewAll()} endIcon={<ArrowForwardIcon />}>View all
                    </Button>
                </div>
            </Paper>
        </Box>
    )
}

export default TopCampaign;
