import React from 'react';
import "../../css/campaign-report/report-header.css";
import editicon from '../../assets/edit-pencil-icon.svg';
import reportCreatorIcon from '../../assets/report-creator-icon.svg';

import calEventIcon from '../../assets/calendar-event-icon.svg';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
// import { getCampaignReportPosts } from './report-repository';
import { getConfig } from '../common/env-config';
import { formatPostDate, formatPostDateWithoutTime } from './date-utils';

function ReportHeader({ reportId , reportData, setReportData, loading, setLoading }) {
    const navigate = useNavigate();
    const { baseUrl } = getConfig();


    // useEffect(() => {
    //     if (!reportId) return;
    //     getCampaignReportPosts(reportId,null, null,setLoading)
    //         .then((result) => {
    //             if (result.success) {
    //                 setReportData(result.data?.campaignReport || {});
    //             } else {
    //                 alert(result.message);
    //             }
    //         });
    // }, [reportId, setLoading, setReportData]);


    const handleEditClick = () => {
        navigate('/new-report', { state: { reportData } });
    };

    return (
        <article className="header-report">
            <div className="header-report-wrapper">
                <div className="action-bar">
                    <figure className="icon-wrapper">
                        <ArrowBackIcon className="arrow-back" onClick={() => navigate(-1)} />
                    </figure>
                    <img className='editicon-img' src={editicon} alt='' onClick={handleEditClick} />
                </div>
                <div className="content-container">
                    <header className="report-header">

                        <div
                            className="report-influencers-profile-container report-avatar-box"
                            style={{
                                backgroundImage: `url(${reportData != null && reportData.campaignReport?.profilePictureId ? (
                                    `${baseUrl}/download-insta-profile/${reportData != null && reportData?.campaignReport?.profilePictureId}`
                                ) : (
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 23 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="_verified_dbbth_91"
                                    >
                                        <path
                                            d="M22.5 11L20.06 8.21L20.4 4.52L16.79 3.7L14.9 0.5L11.5 1.96L8.1 0.5L6.21 3.69L2.6 4.5L2.94 8.2L0.5 11L2.94 13.79L2.6 17.49L6.21 18.31L8.1 21.5L11.5 20.03L14.9 21.49L16.79 18.3L20.4 17.48L20.06 13.79L22.5 11ZM8.88 15.01L6.5 12.61C6.11 12.22 6.11 11.59 6.5 11.2L6.57 11.13C6.96 10.74 7.6 10.74 7.99 11.13L9.6 12.75L14.75 7.59C15.14 7.2 15.78 7.2 16.17 7.59L16.24 7.66C16.63 8.05 16.63 8.68 16.24 9.07L10.32 15.01C9.91 15.4 9.28 15.4 8.88 15.01Z"
                                            fill="#4AABED"
                                        ></path>
                                    </svg>
                                )
                                    })`,
                            }}
                        ></div>
                        <div className="report-info">
                            <div className="title-container">
                                <h1 className="report-title">{loading ? "Loading..." : reportData.campaignReport?.campaignName || "-"}</h1>
                                <p className="report-handle">{loading ? "" : `@${reportData.campaignReport?.brandHandle || "-"}`}</p>
                            </div>
                            <div className="metadata">
                                <div className="creator-count">
                                    <img
                                        src={reportCreatorIcon}
                                        alt="Creator icon"
                                        className="metadata-icon"
                                    />
                                    <span>{loading ? "" : `${reportData.campaignReport?.totalCreatorsAccount || 0} creator account`}</span>
                                </div>
                                <span className="separator">|</span>
                                <div className="report-dates">
                                    <img
                                        src={calEventIcon}
                                        alt="Calendar icon"
                                        className="metadata-icon"
                                    />
                                    <time dateTime={loading ? "" : reportData.campaignReport?.startDate}>
                                        {loading
                                            ? "Loading..."
                                            : `${formatPostDateWithoutTime(reportData.campaignReport?.startDate) || "-"} - ${formatPostDateWithoutTime(reportData.campaignReport?.endDate) || "-"}`
                                        }
                                    </time>

                                </div>
                            </div>
                        </div>
                    </header>
                    <p className="update-info">
                        {loading
                            ? "Loading..."
                            : `Info last updated on ${formatPostDate(reportData.campaignReport?.updatedDate || reportData.campaignReport?.creationDate) || "-"
                            }. Updates every 23 hours.`}
                    </p>
                </div>
            </div>
        </article>
    );
}

export default ReportHeader;
