import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../common/alert';
import { getCampaignReportPosts, toggleReloadPosts } from './report-repository';

const SwitchButton = styled(Switch)(({ theme }) => ({
    width: 90,
    height: 40,
    padding: 0,
    display: 'flex',

    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(40px)',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(178deg, #47E457 10.08%, #235228 157.14%)',
                opacity: 1,
            },
        },
    },

    '& .MuiSwitch-thumb': {
        width: 36,
        height: 36,
        backgroundColor: '#fff',
    },

    '& .MuiSwitch-track': {
        borderRadius: 20,
        backgroundColor: '#ccc',
        opacity: 1,
        position: 'relative',
        transition: theme.transitions.create(['background-color'], {
            duration: 300,
        }),

        '&::before, &::after': {
            content: '"ON"',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: 16,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        '&::before': {
            left: 12,
        },
        '&::after': {
            content: '"OFF"',
            right: 12,
        },
    },

    '& .Mui-checked + .MuiSwitch-track::after': {
        opacity: 0,
    },
    '& .MuiSwitch-track::before': {
        opacity: 0,
    },
    '& .Mui-checked + .MuiSwitch-track::before': {
        opacity: 1,
    },
}));

export default function CustomizedSwitches({ reportId, reloadPosts, postId, setReportData, setLoading }) {
    const [checked, setChecked] = useState(reloadPosts || false);
    const [loading, setLocalLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        setChecked(reloadPosts || false);
    }, [reloadPosts]);

    const handleChange = async (event) => {
        if (!postId) {
            setSnackbarMessage("Please add a post link before enabling the toggle button.");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        const isReload = event.target.checked;
        setChecked(isReload);
        setLocalLoading(true);

        const response = await toggleReloadPosts(reportId, isReload);

        if (response?.success) {
            setSnackbarMessage("Post reload enabled successfully.");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
        } else if (!isReload && response?.message === "Update reload posts value for campaign report") {
            setSnackbarMessage("Post reload disabled successfully.");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
        } else {
            setSnackbarMessage(response?.message || "Failed to reload post.");
            setSnackbarSeverity("error");
            setChecked(!isReload);
            setOpenSnackbar(true);
            setLocalLoading(false);
            return;
        }
        setLoading(true);
        setTimeout(async () => {
            const reportResponse = await getCampaignReportPosts(reportId, null, null, setLoading);
            if (reportResponse.success) {
                setReportData(reportResponse.data);
            }
        }, 500);
        setLoading(false);
        setLocalLoading(false); 
    };


    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <FormControlLabel
                control={
                    loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <SwitchButton checked={checked} onChange={handleChange} disabled={loading} />
                    )
                }
            />

            <Alert open={openSnackbar} severity={snackbarSeverity} message={snackbarMessage} onClose={handleSnackbarClose} />
        </>
    );
}
