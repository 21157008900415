import React from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';

function RemovePostDialog({ open, onClose, onConfirm }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <p>Do you really want to remove this post?</p>
                <p>This action cannot be undone.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} style={{ color: "#782EED",fontWeight: "bold"  }}>
                    Cancel
                </Button>
                <Button onClick={onConfirm} style={{ color: "#782EED", fontWeight: "bold" }}>
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RemovePostDialog;
