import { getConfig } from '../common/env-config';
import { refreshToken } from "../common/refresh-token-repo";

const { baseUrl } = getConfig();

export const createCampaignReport = async (campaignReport) => {
  const url = `${baseUrl}/campaigns/report`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      },
      body: JSON.stringify(campaignReport)
    });

    const data = await response.json();

    if (response.ok) {
      return data;
    } else if (response.status === 499) {
      refreshToken();
      return { success: false, message: "Session expired, please try again." };
    } else {
      return { success: false, message: data.message || "Failed to create campaign report" };
    }
  } catch (error) {
    console.error("Error creating campaign report:", error);
  }
};

export const updateCampaignReport = async (reportId, updatedReport) => {
  const url = `${baseUrl}/campaigns/report/${reportId}`;

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      },
      body: JSON.stringify(updatedReport)
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, data: data.campaignReport };
    } else if (response.status === 499) {
      refreshToken();
      return { success: false, message: "Session expired, please try again." };
    } else {
      return { success: false, message: data.message || "Failed to update campaign report" }; // Return error response
    }
  } catch (error) {
    console.error("Error occurred while updating campaign report:", error);
  }
};


export const getAllCampaignReports = async (
  setCampaignReports,
  setLoading,

) => {
  const url = `${baseUrl}/campaigns/reports`;

  try {
    setLoading(true);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      }
    });

    const data = await response.json();

    if (response.ok) {
      if (setCampaignReports) {
        setCampaignReports(data.data);
      }


    } else if (response.status === 499) {
      refreshToken();
    } else {
      console.error("Error occurred while fetching campaign reports:", data.message);


    }
  } catch (error) {
    console.error("Error occurred while fetching campaign reports:", error);


  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};

export const getCampaignReport = async (reportId) => {
  const url = `${baseUrl}/campaigns/report/${encodeURIComponent(reportId)}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, data: data.data };
    } else if (response.status === 499) {
      refreshToken();
      return { success: false, message: "Session expired, please try again." };
    } else {
      return { success: false, message: data.message || "Failed to fetch campaign report" };
    }
  } catch (error) {
    console.error("Error fetching campaign report:", error);
  }
};
export const addPostToCampaignReport = async (reportId, postLink) => {
  const url = `${baseUrl}/campaigns/post/link?reportId=${reportId}&postLink=${encodeURIComponent(postLink)}`;

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/json',
      }
    });

    const data = await response.json();

    if (response.ok) {
      return data;
    } else if (response.status === 499) {
      refreshToken();
      return { success: false, message: "Session expired, please try again." };
    } else {
      return { success: false, message: data.message || "Failed to add post to campaign report" };
    }
  } catch (error) {
    console.error("Error adding post to campaign report:", error);
  }
};
//get post by filter
export const getCampaignReportPosts = async (reportId, postType, creatorHandles, setLoading) => {
  const url = `${baseUrl}/campaigns/posts`;

  try {
    setLoading(true);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      },
      body: JSON.stringify({ reportId, postType, creatorHandles })
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, data: data.report };
    } else if (response.status === 499) {
      refreshToken();
      return { success: false, message: "Session expired, please try again." };
    } else {
      return { success: false, message: data.message || "Failed to fetch campaign report posts" };
    }
  } catch (error) {
    console.error("Error fetching campaign report posts:", error);
    return { success: false, message: "An unexpected error occurred while fetching data." }; // ✅ Ensure error handling
  } finally {
    setLoading(false);
  }
};

//delete post 
export const removePostFromCampaignReport = async (reportId, postId) => {
  const url = `${baseUrl}/campaigns/post?reportId=${reportId}&postId=${postId}`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/json'
      }
    });

    const data = await response.json();

    if (response.ok) {
      return data;
    } else if (response.status === 499) {
      refreshToken();
      return { success: false, message: "Session expired, please try again." };
    } else {
      return { success: false, message: data.message || "Failed to remove post" };
    }
  } catch (error) {
    console.error("Error removing post:", error);
  }
};

//get creators
export const getCreators = async (campaignReportFilterInput) => {
  const url = `${baseUrl}/campaigns/creators`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(campaignReportFilterInput),
    });

    const data = await response.json();

    if (response.ok) {
      return data;
    } else if (response.status === 499) {
      refreshToken();
      return { success: false, message: "Session expired, please try again." };
    } else {
      return { success: false, message: data.message || "Failed to fetch creators" };
    }
  } catch (error) {
    console.error("Error fetching creators:", error);
    return { success: false, message: "Network error while fetching creators" };
  }
};


export const toggleReloadPosts = async (reportId, isReload) => {
  const url = `${baseUrl}/campaigns/report/${reportId}/reload-posts?isReload=${isReload}`;

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, ...data }; 
    } else {
      return { success: false, message: data.message || "Failed to update reload status" };
    }
  } catch (error) {
    console.error("Error updating reload status:", error);
  }
};

//download report
export const downloadCampaignReport = async (setCsvLoading, reportId) => {
  setCsvLoading(true);
  try {
      const response = await fetch(`${baseUrl}/campaigns/download/report/${reportId}`, {
          method: "GET",
          headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          },
      });

      if (!response.ok) {
          throw new Error("Failed to download report");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Campaign_Report_${reportId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
  } catch (error) {
      console.error("Error downloading campaign report:", error);
  } finally {
      setCsvLoading(false);
  }
};

//download report as pdf
export const downloadCampaignReportPdf = async (setPdfLoading, reportId) => {
  setPdfLoading(true);
  try {
      const response = await fetch(`${baseUrl}/campaigns/download?id=${reportId}`, {
          method: "GET",
          headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          },
      });

      if (!response.ok) {
          throw new Error("Failed to download PDF report");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Campaign_Report_${reportId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  } catch (error) {
      console.error("Error downloading campaign report PDF:", error);
  } finally {
      setPdfLoading(false);
  }
};
