import { Box } from '@mui/material';
import React from 'react'
import CommonAppbarDrawer from '../common/common-sidebar-navbar';
import { DrawerHeader } from '../../mui/appbar-style-component';
import { Outlet } from 'react-router-dom';

import CreateCampaignReport from './create-report';

function CreateReportDashboard() {
    return (
        <Box sx={{ display: 'flex' }}>
            <CommonAppbarDrawer />
            <Box component="main" sx={{ flexGrow: 1, padding: '58px' }}>
                <DrawerHeader />
                <Outlet />
               < CreateCampaignReport/>
            </Box>
        </Box>
    )
}

export default CreateReportDashboard;
