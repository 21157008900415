import React, { useContext, useEffect, useState } from "react";
import "../../css/campaign.css";
import { Badge, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, useMediaQuery, useTheme } from "@mui/material";
import { cancelCampaign, closeCampaign, discardCampiagn, updateCampaignViewCount, useUpdateCampaignStatus } from "./campaign-repository";
import InstagramIcon from '@mui/icons-material/Instagram';
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { getCampaign, handleApplyCampaign } from "./campaign-repository";
import { UserDetailsContext } from "../context/user-details-context";
import MailIcon from '@mui/icons-material/Mail';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import DialogBox from "../common/dialog-box";
import CustomAlert from "../common/alert";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function CampaignDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const updateCampaignsStatus = useUpdateCampaignStatus();
    const pathname = window.location.pathname;
    const pathSegments = pathname.split('/');
    const startPath = '/' + pathSegments[1];
    const { loggedUser } = useContext(UserDetailsContext);
    const [campaign, setCampaign] = useState();
    const [openInstaHandleAlert, setOpenInstaHandleAlert] = useState(false);
    const [apply, setApply] = useState('');
    const [campaignid] = useState(location.state.campaignid);
    const date = new Date();
    const currentDate = date.toISOString().slice(0, 10);
    const [openClosedCampaign, setOpenClosedCampaign] = useState(false);
    const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [invoiceField, setInvoiceField] = useState({
        invoiceNumber: "", paymentId: ""
    });

    useEffect(() => {
        getCampaign(campaignid, setCampaign);
        if (loggedUser && loggedUser.role === "CREATOR") {
            updateCampaignViewCount(campaignid);
        }
    }, [apply, campaignid, loggedUser]);

    const handleEditCampaign = (campaignStatus) => {
        const pathSegments = pathname.split('/');
        const startPath = '/' + pathSegments[1];
        navigate(`${startPath}/create`, { state: { campaign, status: campaignStatus, fromPage: 'details' } });
    };

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [openWithdrawConfirmationDialog, setOpenWithdrawConfirmationDialog] = useState(false);

    // const handleRaiseInvoice = (campaignName) => {
    //     const pathSegments = pathname.split('/');
    //     const startPath = '/' + pathSegments[1];
    //     const invoiceId = campaign.invoiceId;
    //     if (invoiceId) {
    //         navigate(`${startPath}/invoice/${invoiceId}`, { state: { campaign, fromPage: 'details' } });
    //     } else {
    //         navigate(`${startPath}/invoice/create-invoice`, { state: { campaign, fromPage: 'details' } });
    //     }
    // };

    const openDialogForCloseCampaign = () => {
        setOpenClosedCampaign(!openClosedCampaign);
    }

    const closedCampaignAction = () => {
        if (campaign.status === 'Live') {
            closeCampaign((campaign && campaign.campaignId), campaign.status, navigate);
        } else if (campaign.status === 'Draft') {
            discardCampiagn((campaign && campaign.campaignId), navigate);
        } else if (campaign.status === 'Pending') {
            cancelCampaign((campaign && campaign.campaignId), campaign.status, navigate);
        }
    }

    const handleCampaignApplication = () => {
        const pathSegments = pathname.split('/');
        const startPath = '/' + pathSegments[1];
        navigate(`${startPath}/applications`, { state: { campaignId: campaign.campaignId, campaignName: campaign.campaignName, noOfCreators: campaign.creators, campaignLastDate: campaign.lastDate, page: 'details' } });
    }

    const handleInvoiceDialogOpen = () => {
        setOpenInvoiceDialog(true);
    };

    const handleInvoiceDialogClose = () => {
        setOpenInvoiceDialog(false);
    };

    const handleInvoiceChange = (fieldName, value) => {
        setInvoiceField((invoiceField) => {
            return {
                ...invoiceField,
                [fieldName]: value
            };
        });
    }

    return (
        <>
            {!campaign ? (
                <div className="data-loading">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                </div>
            ) : (
                <Box component="main" sx={{ width: "100%" }}>
                    <div className="main-content">
                        <div className="campaign-details">
                            <div className="campaign-details-head">
                                <ArrowBackIcon className="arrow-back" onClick={() => navigate(`${startPath}/${campaign.status.toLowerCase()}`)}></ArrowBackIcon>
                                <h1 className="campaign-header-name">{campaign.campaignName}</h1>
                                <div className="socialmedia-btn lite">
                                    <InstagramIcon sx={{ fontSize: 20 }} />
                                    <label>Instagram</label>
                                </div>
                            </div>
                            <div className="campaign-details-options">
                                {campaign.status === 'Live' &&
                                    <>
                                        <Badge onClick={() => {
                                            if ((campaign.campaignApplicants.length > 0 &&
                                                loggedUser.role === 'ADMIN') ||
                                                (campaign.campaignApplicants.length > 0 && loggedUser.role === 'ENTERPRISE_CLIENT' && campaign.campaignOwner.id === loggedUser.userId)
                                            ) {
                                                handleCampaignApplication();
                                            }
                                        }}
                                            sx={{
                                                cursor: (campaign.campaignApplicants.length > 0 && campaign.campaignApplicants.filter(applicant => applicant.appliedStatus === 'Apply').length > 0 &&
                                                    loggedUser.role === 'ADMIN') ||
                                                    (campaign.campaignApplicants.length > 0 && campaign.campaignApplicants.filter(applicant => applicant.appliedStatus === 'Apply').length > 0 && loggedUser.role === 'ENTERPRISE_CLIENT' && campaign.campaignOwner.id === loggedUser.userId)
                                                    ? 'pointer' : 'context-menu'
                                            }} color="secondary" badgeContent={campaign.campaignApplicants.length > 0 ? campaign.campaignApplicants.filter(applicant => applicant.appliedStatus === 'Apply').length : 0} showZero>
                                            <MailIcon />
                                        </Badge>
                                        {campaign.campaignOwner.id !== loggedUser.userId && loggedUser.role === 'CREATOR' && campaign.lastDate >= currentDate && (
                                            <Button variant="contained" sx={{
                                                backgroundColor: '#e563a4', '&:hover': {
                                                    backgroundColor: '#e326a4'
                                                }
                                            }} onClick={(e) => {
                                                if (campaign.campaignApplicants != null && campaign.campaignApplicants.some(applicant =>
                                                    applicant.appliedStatus === 'Apply' &&
                                                    applicant.creatorId === loggedUser.userId
                                                )) {
                                                    setOpenWithdrawConfirmationDialog(true);
                                                } else {
                                                    handleApplyCampaign(e, campaign.campaignId, loggedUser.isInstaAccountPrivate, setApply, setOpenInstaHandleAlert, setAlertOpen, setAlertMessage, setAlertSeverity);
                                                }
                                            }}
                                                startIcon={campaign.campaignApplicants != null && campaign.campaignApplicants.some(applicant =>
                                                    applicant.appliedStatus !== 'Apply' &&
                                                    applicant.creatorId === loggedUser.userId
                                                ) ? <TouchAppIcon /> : <MoveToInboxIcon />}>
                                                {campaign.campaignApplicants != null ? campaign.campaignApplicants.some(applicant =>
                                                    applicant.appliedStatus === 'Apply' &&
                                                    applicant.creatorId === loggedUser.userId
                                                ) ? 'Withdraw' : 'Apply' : 'Apply'}
                                            </Button>
                                        )}
                                    </>
                                }
                                {['Draft', 'Live', 'Pending'].includes(campaign.status) === true && loggedUser.role === 'ADMIN' &&
                                    <>
                                        <Button variant="contained" sx={{
                                            backgroundColor: '#7b5ede', '&:hover': {
                                                backgroundColor: '#5a32e4'
                                            }
                                        }} onClick={() => handleEditCampaign(campaign.status)}
                                            startIcon={<EditIcon />}>Edit</Button>
                                    </>
                                }

                                {campaign.status === 'Pending' && loggedUser.role === 'ADMIN' && (
                                    <>
                                        <Button variant="contained" sx={{
                                            backgroundColor: '#e563a4', '&:hover': {
                                                backgroundColor: '#e326a4'
                                            }
                                        }} onClick={handleInvoiceDialogOpen}
                                            startIcon={<CheckCircleIcon />}>Accept Campaign
                                        </Button>
                                        <Dialog fullScreen={fullScreen} open={openInvoiceDialog}
                                            onClose={handleInvoiceDialogClose} aria-labelledby="responsive-dialog-title">
                                            <DialogTitle id="responsive-dialog-title">
                                                {"Please Enter Following Details"}
                                            </DialogTitle>
                                            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '12px', paddingTop: '12px !important' }}>
                                                <TextField id="outlined-basic" label="Invoice Number" variant="outlined" name="invoiceNumber"
                                                    value={invoiceField.invoiceNumber} onChange={(e) => handleInvoiceChange("invoiceNumber", e.target.value.replace(/\D/g, ""))}
                                                    inputProps={{ maxLength: 3 }} />
                                                <TextField id="outlined-basic" label="Payment Id" variant="outlined" name="paymentId"
                                                    value={invoiceField.paymentId} onChange={(e) => handleInvoiceChange("paymentId", e.target.value.replace(/[^a-zA-Z0-9]/g, ''))}
                                                    error={!/^[A-Z]{2}\d{2}[A-Z]{3}$/.test(invoiceField.paymentId) && invoiceField.paymentId !== ""}
                                                    helperText={!/^[A-Z]{2}\d{2}[A-Z]{3}$/.test(invoiceField.paymentId) && invoiceField.paymentId !== "" ? 'Incorrect Payment ID' : ''} />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button autoFocus onClick={handleInvoiceDialogClose}>
                                                    Cancel
                                                </Button>
                                                <Button disabled={invoiceField.invoiceNumber === "" || invoiceField.paymentId === "" ||
                                                    !/^[A-Z]{2}\d{2}[A-Z]{3}$/.test(invoiceField.paymentId) || invoiceField.invoiceNumber.length < 3
                                                } onClick={() => updateCampaignsStatus((campaign && campaign.campaignId), 'Live', setAlertOpen, setAlertMessage, setAlertSeverity, navigate)} autoFocus>
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                )}
                                {(campaign.status === 'Live' || campaign.status === 'Pending' || campaign.status === 'Draft') && loggedUser.role === 'ADMIN' && (
                                    <>
                                        <Button variant="contained" color="error"
                                            onClick={() => openDialogForCloseCampaign()}
                                            startIcon={<HighlightOffIcon />}>{campaign.status === 'Draft' ? 'Discard Campaign' :
                                                campaign.status === 'Pending' ? 'Cancel Campaign' : 'Close Campaign'}
                                        </Button>
                                        <Dialog
                                            open={openClosedCampaign}
                                            onClose={openDialogForCloseCampaign}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Warning"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    Are you sure want to {campaign.status === 'Draft' ? 'discard campaign' :
                                                        campaign.status === 'Pending' ? 'cancel campaign' : 'close campaign'}?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={openDialogForCloseCampaign}>Cancel</Button>
                                                <Button onClick={(e) => { closedCampaignAction(e); }} autoFocus>
                                                    {campaign.status === 'Draft' ? 'Discard Campaign' :
                                                        campaign.status === 'Pending' ? 'Cancel Campaign' : 'Close Campaign'}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                )}
                                {/* {campaign.status === 'Completed' && loggedUser.role === 'ADMIN' &&
                                                    <>

                                                        {campaign.invoiceStatus === 'Draft' ? (
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: '#7b5ede',
                                                                    '&:hover': {
                                                                        backgroundColor: '#5a32e4',
                                                                    },
                                                                }}
                                                                onClick={() => handleRaiseInvoice(campaign.status)}
                                                            >
                                                                Complete invoice
                                                            </Button>
                                                        ) : campaign.invoiceStatus === 'Completed' || campaign.invoiceStatus === 'Pending' ? (
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: '#7b5ede',
                                                                    '&:hover': {
                                                                        backgroundColor: '#5a32e4',
                                                                    },
                                                                }}
                                                                onClick={() => handleRaiseInvoice(campaign.invoiceId)}
                                                            >
                                                                View invoice
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: '#7b5ede',
                                                                    '&:hover': {
                                                                        backgroundColor: '#5a32e4',
                                                                    },
                                                                }}
                                                                onClick={() => handleRaiseInvoice(campaign.status)}
                                                            >
                                                                Raise invoice
                                                            </Button>
                                                        )}

                                                    </>
                                                } */}

                            </div>
                            <div className="campaign-list-hr"></div>
                            <div className="campaign-overview">
                                <div className="campaign-overview-header">
                                    <h1>Campaign Overview</h1>
                                </div>
                                <div className="campaign-data">
                                    <div>Channel: <span>Instagram</span></div>
                                    <div className="option-row">
                                        <div>Campaign start: <span>{moment(campaign.lastDate).format("ll")}</span></div>
                                        <div>Campaign end: <span>{moment(campaign.lastDate).add(7, 'day').format("ll")}</span></div>
                                    </div>
                                    {pathname.includes('/all-campaigns') && loggedUser.role === 'ADMIN' && (
                                        <div>Created By: <span>{campaign.createdBy === null ? "Unknown" : campaign.createdBy.fullName}</span></div>
                                    )}
                                    {pathname.includes('/all-campaigns') && loggedUser.role === 'ADMIN' && (
                                        <div>Campaign Owner: <span>{campaign.campaignOwner.fullName}</span></div>
                                    )}
                                    {pathname.includes('/all-campaigns') && (loggedUser.role === 'ADMIN' || loggedUser.role === 'CREATOR') && (
                                        <div>Brand Handle: <span>@{campaign.brandHandle}</span></div>
                                    )}
                                    <div className="description-outer-container">
                                        <div className="adjust-alignment campaign-brief">Brief:</div>
                                        <div>
                                            <div className="description-content" dangerouslySetInnerHTML={{ __html: campaign.description }} />
                                        </div>
                                    </div>
                                    <div>Project Type: <span>{campaign.projectType?.projectType}</span></div>
                                    <div>Shoot Location Type: <span>{campaign.shootLocationType?.shootLocationType}</span></div>
                                    <div>Languages: <span>{campaign.languages?.map(lang => lang.language).join(", ")}</span></div>
                                    <div>Gender: <span>{campaign.gender?.join(", ")}</span></div>
                                    <div>Age Range: <span>{campaign.ageRange?.map(ar => ar.ageRange).join(", ")}</span></div>
                                    <div>Target Types: <span>
                                        {campaign.categoryTypes && campaign.categoryTypes.some(item => item !== '') ? campaign.categoryTypes.join(', ') : ''}
                                        {campaign.categoryTypes && campaign.categoryTypes.some(item => item !== '') && campaign.customCategory && campaign.customCategory.some(item => item !== '') ? ', ' : ''}
                                        {campaign.customCategory && campaign.customCategory.some(item => item !== '') ? campaign.customCategory.filter(item => item !== '').map(item => item.replace(/"/g, '')).join(', ') : ''}
                                    </span></div>
                                    <div>Campaign Category Type: <span>{campaign.campaignCategoryType?.campaignCategoryType}</span></div>
                                    <div>Location: <span>{campaign.campaignLocations ? campaign.campaignLocations.map(cityState => `${cityState}`).join(', ') : ''}</span></div>
                                    <div>Number of creators: <span>{campaign.creators}</span></div>
                                </div>

                            </div>
                            <div className="alert-msg">
                                <CustomAlert open={alertOpen} severity={alertSeverity} message={alertMessage} onClose={() => setAlertOpen(false)} />
                            </div>
                        </div>
                    </div>
                </Box>
            )
            }
            <DialogBox userDetails={loggedUser} openAlert={openInstaHandleAlert} setOpenAlert={setOpenInstaHandleAlert} campaignId={campaignid} />
            <Dialog
                open={openWithdrawConfirmationDialog}
                onClose={() => setOpenWithdrawConfirmationDialog(false)}
                aria-labelledby="withdraw-confirmation-dialog-title"
                aria-describedby="withdraw-confirmation-dialog-description"
            >
                <DialogTitle id="withdraw-confirmation-dialog-title" sx={{ color: '#e326a4' }}>Withdraw Application</DialogTitle>
                <DialogContent>
                    <DialogContentText id="withdraw-confirmation-dialog-description" sx={{ color: '#000000' }}>
                        Do you really want to withdraw your application?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenWithdrawConfirmationDialog(false)}>No</Button>
                    <Button onClick={(e) => {
                        handleApplyCampaign(e, campaign.campaignId, loggedUser.isInstaAccountPrivate, setApply, setOpenInstaHandleAlert, setAlertOpen, setAlertMessage, setAlertSeverity);
                        setOpenWithdrawConfirmationDialog(false);
                    }}>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CampaignDetails;
