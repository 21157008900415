import React, { useState, useEffect, useRef, useMemo } from "react";
import "../../css/campaign-report/add-post.css";
import { Autocomplete, Card, Checkbox, CircularProgress, Input, TextField } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import {  downloadCampaignReportPdf, getCampaignReportPosts, getCreators } from "./report-repository";
import eyeIcon from '../../assets/showIcon.svg';
import heartIcon from '../../assets/Heart_01.svg';
import commentIcon from '../../assets/chat-circle-icon.svg';
import downloadIcon from '../../assets/Arrow_Down_MD.svg';

import { formatNumber, formatPercentage } from "./date-utils";
import { getConfig } from "../common/env-config";
import defaultProfile from '../../assets/profile.svg';
import CustomizedSwitches from "./switch";


const POST_TYPES = ["All", "Story", "Reels", "Video", "Image", "Post"];
function PostFilters({ reportId, reportData, setReportData, loading, setLoading }) {
    const isMounted = useRef(false);
    const apiRequestInProgress = useRef(false);

    const [selectedPostTypes, setSelectedPostTypes] = useState(POST_TYPES);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedCreators, setSelectedCreators] = useState(["All creator accounts"]);
    const [creators, setCreators] = useState([]);
    const { baseUrl } = getConfig();
    const [shouldFetchData, setShouldFetchData] = useState(false);
    const dropdownRef = useRef(null);
    const [pdfLoading, setPdfLoading] = useState(false);

    useEffect(() => {
        const fetchCreators = async () => {
            try {
                const response = await getCreators({ reportId });
                if (response.success) {
                    const formattedCreators = response.creators.map((creator) => {
                        return {
                            username: `@${creator.instaHandle}`,
                            avatar: creator.profilePicId ? `${baseUrl}/download-insta-profile/${creator.profilePicId}` : "",
                        };
                    });

                    setCreators(formattedCreators);

                    const initialSelection = ["All creator accounts", ...formattedCreators.map(creator => creator.username)];
                    setSelectedCreators(initialSelection);
                }
            } catch (error) {
                console.error("Error fetching creators:", error);
            }
        };
        fetchCreators();
    }, [reportId, baseUrl]);


    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }

        setShouldFetchData(true);
    }, [reportId, selectedCreators, selectedPostTypes]);

    useEffect(() => {
        if (!shouldFetchData || apiRequestInProgress.current) {
            return;
        }

        const fetchReportPosts = async () => {
            apiRequestInProgress.current = true;

            try {
                setShouldFetchData(false);

                const apiPostTypes = selectedPostTypes.includes("All")
                    ? POST_TYPES.filter(type => type !== "All").map(type => type.toUpperCase())
                    : selectedPostTypes.map(type => type.toUpperCase());

                const formattedCreators = selectedCreators
                    .filter(creator => creator !== "All creator accounts")
                    .map(creator => creator.replace(/^@/, ""));

                const response = await getCampaignReportPosts(reportId, apiPostTypes, formattedCreators, setLoading);
                if (response.success) {
                    setReportData(response.data);
                } else {
                    console.error(response.message);
                }
            } catch (error) {
                console.error("Error fetching report posts:", error);
            } finally {
                apiRequestInProgress.current = false;
            }
        };

        const timerId = setTimeout(fetchReportPosts, 300);

        return () => {
            clearTimeout(timerId);
        };
    }, [shouldFetchData, reportId, selectedCreators, selectedPostTypes, setReportData, setLoading]);

    const filteredCreators = useMemo(() => {
        return creators.filter((creator) =>
            creator.username.toLowerCase().includes(search.toLowerCase())
        );
    }, [creators, search]);

    const handleCreatorSelection = (username) => {
        setSelectedCreators((prevSelected) => {
            if (username === "All creator accounts") {
                if (prevSelected.includes("All creator accounts")) {
                    return [];
                }
                return ["All creator accounts", ...creators.map(creator => creator.username)];
            }

            let newSelection;
            if (prevSelected.includes(username)) {
                newSelection = prevSelected.filter(c => c !== username && c !== "All creator accounts");
            } else {
                newSelection = [...prevSelected.filter(c => c !== "All creator accounts"), username];

                const allCreatorsSelected = creators.every(creator =>
                    newSelection.includes(creator.username)
                );

                if (allCreatorsSelected) {
                    newSelection = ["All creator accounts", ...creators.map(creator => creator.username)];
                }
            }

            return newSelection;
        });
    };

    const handlePostTypeSelection = (option) => {
        setSelectedPostTypes(prevSelected => {
            if (option === "All") {
                if (prevSelected.includes("All")) {
                    return [];
                }
                return [...POST_TYPES];
            }

            let newSelection;
            if (prevSelected.includes(option)) {
                newSelection = prevSelected.filter(item => item !== option && item !== "All");
            } else {
                newSelection = [...prevSelected.filter(item => item !== "All"), option];

                const allIndividualSelected = POST_TYPES
                    .filter(type => type !== "All")
                    .every(type => newSelection.includes(type));

                if (allIndividualSelected) {
                    newSelection = [...POST_TYPES];
                }
            }

            return newSelection.length ? newSelection : [];
        });
    };

    const getDisplayText = useMemo(() => {
        if (selectedPostTypes.includes("All")) return "Post Types: All";
        if (selectedPostTypes.length === 0) return "Post Types: ";

        const maxDisplayCount = 1;
        if (selectedPostTypes.length <= maxDisplayCount) {
            return `Post Types: ${selectedPostTypes.join(", ")}`;
        }
        const visibleItems = selectedPostTypes.slice(0, maxDisplayCount).join(", ");
        return `Post Types: ${visibleItems}, ...`;
    }, [selectedPostTypes]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleDownloadReport = async () => {
        await downloadCampaignReportPdf(setPdfLoading, reportId);
    };

    return (
        <section className="dashboard">
            <div className="dashboard-container">
                <header className="dashboard-header">
                    <div className="filters-wrapper">
                        <div className="filter-group">
                            <Autocomplete
                                multiple
                                id="posttypes"
                                options={POST_TYPES}
                                value={selectedPostTypes}
                                open={isDropdownOpen}
                                onOpen={() => setIsDropdownOpen(true)}
                                onClose={() => setIsDropdownOpen(false)}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.toLowerCase()}
                                renderOption={(props, option, { selected }) => (
                                    <li
                                        {...props}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handlePostTypeSelection(option);
                                        }}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <span>{option}</span>
                                        <Checkbox
                                            style={{ marginLeft: "auto" }}
                                            checked={selectedPostTypes.includes(option)}
                                        />
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onKeyDown={(e) => e.preventDefault()}
                                        inputProps={{ ...params.inputProps, readOnly: true }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: getDisplayText,
                                            endAdornment: (
                                                <div
                                                    style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setIsDropdownOpen((prev) => !prev);
                                                    }}
                                                >
                                                    {isDropdownOpen ? <ExpandLess /> : <ExpandMore />}
                                                </div>
                                            ),
                                            style: {
                                                width: "100%",
                                                cursor: "pointer",
                                                paddingRight: "9px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                fontSize: "15px",
                                                padding: "2px 10px",
                                            },
                                        }}
                                    />
                                )}
                                style={{ width: 250 }}
                            />


                            <div className="creator-container" ref={dropdownRef}>
                                <div className="creator-toggle" onClick={() => setIsOpen(!isOpen)}>
                                    <span>Creator accounts{selectedCreators.includes("All creator accounts") ? ": All" : ""}</span>
                                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                                </div>

                                {isOpen && (
                                    <Card className="creator-dropdown">
                                        <Input
                                            placeholder="Enter username or handle"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            className="creator-input"
                                        />
                                        <div className="creator-option" onClick={() => handleCreatorSelection("All creator accounts")}>
                                            <span>All creator accounts</span>
                                            <Checkbox
                                                checked={selectedCreators.includes("All creator accounts")}
                                                style={{ marginLeft: "auto" }}
                                            />
                                        </div>
                                        {filteredCreators.map((creator, index) => (
                                            <div
                                                key={index}
                                                className="creator-item"
                                                onClick={() => handleCreatorSelection(creator.username)}
                                                style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    {creator.avatar ? (
                                                        <img
                                                            src={creator.avatar}
                                                            alt="Profile"
                                                            className="report-influencers-profile-container report-avatar-box"
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                                borderRadius: "50%",
                                                                objectFit: "cover"
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={defaultProfile}
                                                            alt="Default Profile"
                                                            className="default-profile"
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                                borderRadius: "50%",
                                                                objectFit: "cover"
                                                            }}
                                                        />
                                                    )}
                                                    <span>{creator.username}</span>
                                                </div>
                                                <Checkbox checked={selectedCreators.includes(creator.username)} />
                                            </div>
                                        ))}
                                    </Card>
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="download-container">
                        <CustomizedSwitches reportId={reportId} reloadPosts={reportData?.campaignReport?.reloadPosts} postId={reportData?.campaignPosts?.[0]?.postId} setReportData={setReportData} setLoading={setLoading} />
                        <button
                            className="download-btn"
                            onClick={handleDownloadReport}
                            disabled={loading}
                        >
                            {pdfLoading ? (
                                <CircularProgress size={12} />
                            ) : (
                                <img src={downloadIcon} alt="Download Icon" />
                            )}
                            <span>Download detail report</span>
                        </button>
                    </div>

                </header>

                <section className="performance-section">
                    <h2 className="section-title">Performance</h2>

                    {loading ? (
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                            width: "100%"
                        }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="metrics-grid">
                            <div className="metrics-container">
                                <article className="metric-card">
                                    <div className="metric-header">
                                        <div className="metric-main">
                                            <img src={heartIcon} alt="" />
                                            <span className="metric-value">{formatNumber(reportData?.performanceMetrix?.totalLikes ?? 0)}</span>
                                        </div>
                                        <h3 className="metric-title">Total likes</h3>
                                    </div>
                                    <div className="metric-divider"></div>
                                    <p className="metric-average">
                                        <strong>{formatNumber(reportData?.performanceMetrix?.avgLikes ?? 0)}</strong> average likes per post
                                    </p>
                                </article>

                                <article className="metric-card">
                                    <div className="metric-header">
                                        <div className="metric-main">
                                            <img src={commentIcon} alt="" />
                                            <span className="metric-value">{formatNumber(reportData?.performanceMetrix?.totalComments ?? 0)}</span>
                                        </div>
                                        <h3 className="metric-title">Total Comments</h3>
                                    </div>
                                    <div className="metric-divider"></div>
                                    <p className="metric-average">
                                        <strong>{formatNumber(reportData?.performanceMetrix?.avgComments ?? 0)}</strong> average comments per post
                                    </p>
                                </article>

                                <article className="metric-card">
                                    <div className="metric-header">
                                        <div className="metric-main">
                                            <img src={eyeIcon} alt="" />
                                            <span className="metric-value">{formatNumber(reportData?.performanceMetrix?.totalViews ?? 0)}</span>
                                        </div>
                                        <div className="metric-title-wrapper">
                                            <h3 className="metric-title">Total Views</h3>
                                            <img
                                                src="https://cdn.builder.io/api/v1/image/assets/556b441f65504ff29dc357c144376016/e66dc142b73fd2a09282cbbe076ed8ef2278519ab8779cfe3a8bb84ce8bf8114?placeholderIfAbsent=true"
                                                alt="Info"
                                                className="info-icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="metric-divider"></div>
                                    <p className="metric-average">
                                        <strong>{formatNumber(reportData?.performanceMetrix?.avgViews ?? 0)}</strong> average views per post
                                    </p>
                                </article>

                                <article className="metric-card">
                                    <div className="metric-header">
                                        <div className="metric-main">
                                            <span className="metric-value">{formatNumber(reportData?.performanceMetrix?.totalEngagement ?? 0)}</span>
                                        </div>
                                        <div className="metric-title-wrapper">
                                            <h3 className="metric-title">Total Engagement</h3>
                                            <img
                                                src="https://cdn.builder.io/api/v1/image/assets/556b441f65504ff29dc357c144376016/75bec3481115160832e8f6ce7c8f7fb3d0a6cce39e1a76e0f3ebf8b65ccc84d2?placeholderIfAbsent=true"
                                                alt="Info"
                                                className="info-icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="metric-divider"></div>
                                    <p className="metric-average">
                                        <strong>{formatNumber(reportData?.performanceMetrix?.avgEngagement ?? 0)}</strong> average engagements per post
                                    </p>
                                </article>

                                <article className="metric-card">
                                    <div className="metric-header">
                                        <div className="metric-main">
                                            <span className="metric-value">{formatNumber(reportData?.performanceMetrix?.totalImpressions ?? 0)}</span>
                                        </div>
                                        <h3 className="metric-title">Total Impressions</h3>
                                    </div>
                                    <div className="metric-divider"></div>
                                    <p className="metric-average">
                                        <strong>{formatNumber(reportData?.performanceMetrix?.avgImpressions ?? 0)}</strong> average impressions per post
                                    </p>
                                </article>

                                <article className="metric-card">
                                    <div className="metric-header">
                                        <div className="metric-main">
                                            <span className="metric-value">{formatPercentage(reportData?.performanceMetrix?.totalEngagementRate ?? 0)}</span>
                                        </div>
                                        <h3 className="metric-title">Engagement Rate</h3>
                                    </div>
                                    <div className="metric-divider"></div>
                                    <p className="metric-average">
                                        <strong>{formatPercentage(reportData?.performanceMetrix?.avgEngagementRate ?? 0)}</strong> average Eng. rate
                                    </p>
                                </article>
                            </div>
                        </div>
                    )}
                </section>

                <div className="dashboard-divider"></div>
            </div>
        </section>
    );
}

export default PostFilters;