import React, { useRef, useState, useEffect } from "react";
import { createCampaignReport } from "../../components/createreport/report-repository";
import { updateCampaignReport } from "../../components/createreport/report-repository";
import "../../css/campaign-report/create-campaign-report.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useLocation } from "react-router-dom";
import SuccessDialog from "./success-dialog";
import createreporticon from '../../assets/create-report-icon.jpg';
import editIcon from '../../assets/edit-report-icon.svg';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from 'dayjs';


function CreateCampaignReport() {
  const campaignNameRef = useRef(null);
  const brandHandleRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [campaignNameError, setCampaignNameError] = useState("");
  const [brandHandleError, setBrandHandleError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [reportId, setReportId] = useState(null);

  useEffect(() => {
    if (location.state && location.state.reportData) {
      const { reportData } = location.state;
      setCampaignNameError("");
      setBrandHandleError("");
      setStartDateError("");
      setEndDateError("");
      campaignNameRef.current.value = reportData.campaignReport.campaignName;
      brandHandleRef.current.value = reportData.campaignReport.brandHandle;
      setStartDate(reportData.campaignReport.startDate ? dayjs(reportData.campaignReport.startDate) : null);
      setEndDate(reportData.campaignReport.endDate ? dayjs(reportData.campaignReport.endDate) : null);
      setIsEditMode(true);
      setReportId(reportData.campaignReport.reportId);
    }

  }, [location.state, reportId, isEditMode]);

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
    if (newValue) {
      setEndDate((prevEndDate) => (prevEndDate && prevEndDate < newValue ? newValue : prevEndDate));
    }
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setCampaignNameError("");
    setBrandHandleError("");
    setStartDateError("");
    setEndDateError("");

    const campaignName = campaignNameRef.current.value;
    const brandHandle = brandHandleRef.current.value;
    let formValid = true;

    if (!campaignName) {
      setCampaignNameError("Campaign name is required.");
      formValid = false;
    }

    const brandHandleRegex = /^[A-Za-z0-9._]+$/;
    if (!brandHandle) {
      setBrandHandleError("Brand handle is required.");
      formValid = false;
    } else if (!brandHandleRegex.test(brandHandle)) {
      setBrandHandleError("Brand handle must only contain letters, numbers, periods, and underscores.");
      formValid = false;
    }

    if (!startDate) {
      setStartDateError("Start date is required.");
      formValid = false;
    }

    if (!endDate) {
      setEndDateError("End date is required.");
      formValid = false;
    } else if (startDate && endDate && endDate < startDate) {
      setEndDateError("End date must be after start date.");
      formValid = false;
    }

    if (!formValid) {
      setLoading(false);
      return;
    }

    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");

    const reportData = {
      campaignName,
      brandHandle,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };


    try {
      let response;
      if (isEditMode && reportId) {
        response = await updateCampaignReport(reportId, reportData);
      } else {
        response = await createCampaignReport(reportData);
      }
      if (!response.success) {
        if (response.message === "Campaign Name already exists.") {
          setCampaignNameError("Campaign Name already exists.");
        } else if (response.message.includes("Start date must be today or a future date")) {
          setStartDateError("Start date must be today or a future date.");
        } else if (response.message.includes("End date must be today or a future date")) {
          setEndDateError("End date must be today or a future date.");
        } else {
          alert(response.message);
        }

        setLoading(false);
        return;
      }

      campaignNameRef.current.value = "";
      brandHandleRef.current.value = "";
      setStartDate(null);
      setEndDate(null);
      setDialogOpen(true);

      setTimeout(() => {
        setDialogOpen(false);
        if (isEditMode && reportId) {
          navigate(`/campaign-report`, { state: { reportId } });
        } else {
          navigate("/campaign-report-list");
        }
      }, 2000);
    } catch (error) {
      alert("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    campaignNameRef.current.value = "";
    brandHandleRef.current.value = "";
    setStartDate(null);
    setEndDate(null);
    setCampaignNameError("");
    setBrandHandleError("");
    setStartDateError("");
    setEndDateError("");
    navigate(-1);
  };

  return (
    <main className="edit-campaign">
      <div className="edit-campaign__container">
        <section className="campaign-form">
          <ArrowBackIcon className="arrow-back" onClick={() => navigate(-1)}></ArrowBackIcon>

          <header className="campaign-form__header">
            <div className="icon-wrapper">
              <img className="create-img" src={isEditMode ? editIcon : createreporticon} alt="" />
            </div>
            <h1 className="campaign-form__title">{isEditMode ? "Edit Campaign Report" : "Create New Report"}</h1>
          </header>

          <form className="campaign-details">
            <div className="form-group">
              <label className="form-label" htmlFor="campaignName">
                Campaign Name
              </label>
              <input type="text" id="campaignName" className="form-input" ref={campaignNameRef} />
              {campaignNameError && <p className="error-message">{campaignNameError}</p>}
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="brandHandle">
                Brand Handle
              </label>
              <input type="text" id="brandHandle" className="form-input" ref={brandHandleRef} />
              {brandHandleError && <p className="error-message">{brandHandleError}</p>}
            </div>

            <section className="duration-section">
              <header className="duration-section__header">
                <div className="icon-wrapper">
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/556b441f65504ff29dc357c144376016/93dca3caaa36d7a7013d924058fc706e4139f34440a972838247daa1b8a107e5?placeholderIfAbsent=true"
                    alt="Duration"
                    className="icon-wrapper__image"
                  />
                </div>
                <h2 className="duration-section__title">Campaign Duration</h2>
              </header>

              <div className="duration-section__info">
                <p className="duration-section__description">
                  We actively update the campaign performance details in this duration.
                </p>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/556b441f65504ff29dc357c144376016/73065611c902ee6aa1ff4bd647754fc6364b7332309942255ac78778e02ffbb5?placeholderIfAbsent=true"
                  alt="Info"
                  className="duration-section__icon"
                />
              </div>

              <div className="date-inputs">
                <div className="form-group">
                  <label className="form-label" htmlFor="startDate">
                    Start date
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker value={startDate} onChange={handleStartDateChange} disablePast disabled={isEditMode} />
                  </LocalizationProvider>
                  {startDateError && <p className="error-message">{startDateError}</p>}
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="endDate">
                    End date
                  </label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker value={endDate} onChange={handleEndDateChange} minDate={startDate} disablePast />
                  </LocalizationProvider>
                  {endDateError && <p className="error-message">{endDateError}</p>}
                </div>
              </div>
            </section>

            <footer className="campaign-form__actions">
              <button type="button" className="button button--secondary" onClick={handleCancel}>
                {isEditMode ? "Discard" : "Cancel"}
              </button>
              <button type="button" className="button button--primary" onClick={handleSubmit} disabled={loading}>
                {isEditMode ? "Update" : "Create"}
              </button>
            </footer>
          </form>
        </section>
      </div>

      <SuccessDialog open={dialogOpen} message={isEditMode ? "Your changes have been updated." : "Report created successfully!"} />

    </main>
  );
}

export default CreateCampaignReport;
