import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../css/campaign.css";
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, TextField, FormControlLabel, Checkbox, Button, Autocomplete, Chip, Paper, Radio, RadioGroup, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { campaignNameText } from "../../mui/mui-styling";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import CustomAlert from "../common/alert";
import Alert from '@mui/material/Alert';
import { fetchEnterpriseClients, fetchLanguages, useCreateCampaign, useUpdateCampaign } from "./campaign-repository";
import { fetchCampaignCategoryType, fetchCategories } from '../common/common-apis-repo';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { UserDetailsContext } from "../context/user-details-context";
import DialogBox from "../common/dialog-box";
import CampaignLocation from "./campaign-location";

function CreateCampaign() {
  const location = useLocation();
  const { campaign, fromPage } = location.state || {};
  const [userId, setUserId] = useState(campaign ? campaign.campaignOwner.id : location.state?.userId);
  const userName = location.state?.userName;
  const enterpriseClient = location.state?.enterpriseClient;
  const { loggedUser } = useContext(UserDetailsContext);
  const createCampaign = useCreateCampaign();
  const updateCampaign = useUpdateCampaign();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const reactQuillRef = useRef();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [campaignData, setCampaignData] = useState({
    brandHandle: campaign?.brandHandle || null,
    lastDate: campaign?.lastDate ? dayjs(campaign.lastDate) : dayjs().add(3, 'day'),
    campaignName: campaign?.campaignName || null,
    description: campaign?.description || null,
    projectType: campaign?.projectType?.projectType || null,
    categoryTypes: campaign?.categoryTypes || [],
    customCategory: campaign?.customCategory || [],
    newCategory: null,
    selectedCategories: [],
    campaignLocations: campaign?.campaignLocations || [],
    placeIds: campaign?.placeIds || [],
    creators: campaign?.creators || 1,
    campaignOwner: campaign?.campaignOwner || null,
    createdBy: campaign?.createdBy || null,
    languages: campaign?.languages || [],
    gender: campaign?.gender || [],
    shootLocationType: campaign?.shootLocationType?.shootLocationType || null,
    ageRange: campaign?.ageRange?.map(age => age.ageRange) || [],
    campaignCategoryType: campaign?.campaignCategoryType || null
  });

  const [categories, setCategories] = useState([]);
  const [showCategories, setShowCategories] = useState([]);
  const [campaignCategoryType, setCampaignCategoryType] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [enterpriseClients, setEnterpriseClients] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedEC, setSelectedEC] = useState(null);
  const [campaignOwner, setCampaignOwner] = useState(null);
  const [error, setError] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const [openBillingDetailAlert, setOpenBillingDetailAlert] = useState(false);

  const handleChange = (event, value, nameOverride) => {
    let name, parsedValue, type, checked;

    if (nameOverride) {
      name = nameOverride;
      parsedValue = value;
      type = 'text';
    } else {
      ({ name, value, type, checked } = event.target);
      parsedValue = type === 'number' ? parseInt(value, 10) : value;
    }

    setCampaignData((prevData) => {
      let updatedData = { ...prevData };

      switch (name) {
        case "selectedCategories":
          if (Array.isArray(parsedValue) && parsedValue.includes("Others")) {
            handleDialogOpen();
            parsedValue = parsedValue.filter(item => item !== 'Others');
          }
          updatedData.selectedCategories = parsedValue;
          updatedData.categoryTypes = categories.filter(category => parsedValue.includes(category));
          break;

        case "campaignLocations":
          updatedData.campaignLocations = parsedValue;
          break;

        case "placeIds":
          updatedData.placeIds = parsedValue;
          break;

        case "selectedEC":
          if (parsedValue) {
            setUserId(parsedValue.userId);
            setSelectedEC(parsedValue);
            setSelectedUser(parsedValue);
          } else {
            setUserId(null);
            setSelectedEC(null);
            setSelectedUser(null);
          }
          updatedData.selectedEC = parsedValue;
          break;

        case "projectType":
          updatedData.projectType = parsedValue;
          break;

        case "brandHandle":
          const regexPattern = /^[a-zA-Z0-9._]+$/;
          updatedData.brandHandle = parsedValue;
          setError(!regexPattern.test(parsedValue));
          break;

        case "lastDate":
          const dateValue = dayjs(parsedValue);
          updatedData.lastDate = dateValue.isBefore(dayjs()) ? dayjs() : dateValue;
          break;

        case "description":
          updatedData.description = parsedValue;
          break;

        case "newCategory":
          updatedData.newCategory = parsedValue;
          break;

        case "languages":
          updatedData.languages = parsedValue;
          break;

        case "gender":
          updatedData.gender = parsedValue;
          break;

        case "shootLocationType":
          updatedData.shootLocationType = parsedValue;
          break;

        case "ageRange":
          updatedData.ageRange = parsedValue;
          break;

        case "campaignCategoryType":
          updatedData.campaignCategoryType = parsedValue;
          break;

        default:
          updatedData[name] = type === 'checkbox' ? checked : parsedValue;
          break;
      }

      return updatedData;
    });

    const isFieldDirty = field => {
      if (Array.isArray(field)) {
        return field.length > 0;
      }
      return field !== '' && field !== null;
    };

    let isFormDirty = [
      campaignData.brandHandle,
      campaignData.campaignName,
      campaignData.description,
      campaignData.newCategory,
      campaignData.campaignLocations,
      campaignData.placeIds,
      campaignData.selectedCategories,
      campaignData.categoryTypes,
      campaignData.customCategory,
      campaignData.projectType,
      campaignData.languages,
      campaignData.gender,
      campaignData.shootLocationType,
      campaignData.ageRange,
      campaignData.campaignCategoryType
    ].some(isFieldDirty) ||
      campaignData.creators > 1

    if (campaign && (campaign.someValue !== campaignData.someValue)) {
      isFormDirty = true;
    }

    setIsFormDirty(isFormDirty);
  };

  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef.current.getEditor();
    if (unprivilegedEditor.getLength() > 2000 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleCampaignAction = (status) => {
    if (dayjs().isAfter(campaignData.lastDate, 'day')) {
      showAlert('Last date of application is over, please change the date', 'error');
      return;
    }

    if (campaignData.creators < 1) {
      showAlert('Please enter number of creator', 'error');
      return;
    }

    if (status === 'Draft' || (selectedUser && selectedUser.additionalDetails)) {
      setIsFormDirty(false);
      const args = [
        campaignData.campaignName,
        campaignData.description,
        campaignData.projectType,
        campaignData.categoryTypes,
        campaignData.customCategory,
        campaignData.campaignLocations,
        campaignData.placeIds,
        campaignData.creators,
        status,
        campaignData.lastDate,
        setAlertOpen,
        setAlertMessage,
        setAlertSeverity,
        campaignData.brandHandle,
        campaignData.campaignOwner = campaignOwner,
        campaignData.createdBy = { id: loggedUser.userId, fullName: `${loggedUser.firstName} ${loggedUser.lastName}` },
        campaignData.languages,
        campaignData.gender,
        campaignData.shootLocationType,
        campaignData.ageRange,
        campaignData.campaignCategoryType
      ];

      (campaign && campaign.campaignId) ? updateCampaign((campaign && campaign.campaignId), ...args) : createCampaign(...args);
    } else if (loggedUser.role === 'ENTERPRISE_CLIENT') {
      if (status === 'Draft' || (loggedUser && loggedUser.additionalDetails)) {
        setIsFormDirty(false);
        const args = [
          campaignData.campaignName,
          campaignData.description,
          campaignData.projectType,
          campaignData.categoryTypes,
          campaignData.customCategory,
          campaignData.campaignLocations,
          campaignData.placeIds,
          campaignData.creators,
          status,
          campaignData.lastDate,
          setAlertOpen,
          setAlertMessage,
          setAlertSeverity,
          campaignData.brandHandle,
          campaignData.campaignOwner = campaignOwner,
          campaignData.createdBy = { id: loggedUser.userId, fullName: `${loggedUser.firstName} ${loggedUser.lastName}` },
          campaignData.languages,
          campaignData.gender,
          campaignData.shootLocationType,
          campaignData.ageRange,
          campaignData.campaignCategoryType
        ];

        (campaign && campaign.campaignId) ? updateCampaign((campaign && campaign.campaignId), ...args) : createCampaign(...args);
      } else {
        setOpenBillingDetailAlert(true);
      }
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchEnterpriseClients(setEnterpriseClients);
    fetchCategories((categories) => {
      const updatedCategories = [...categories, ...campaignData.customCategory];
      setShowCategories(updatedCategories);
      setCategories(categories);
    });
    fetchCampaignCategoryType(setCampaignCategoryType);
    fetchLanguages(setLanguages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId !== null) {
      const selectedClient = enterpriseClients.find(client => client.userId === userId);
      setSelectedEC(selectedClient || null);
      setSelectedUser(selectedClient || null);
      setCampaignOwner({
        id: selectedClient && selectedClient.userId,
        fullName: selectedClient && `${selectedClient.firstName} ${selectedClient.lastName}`,
        phoneNumber: selectedClient && selectedClient.phoneNumber
      });
    }
  }, [userId, enterpriseClients]);

  const handleNewCategorySubmit = () => {
    const updatedShowCategories = [...showCategories, campaignData.newCategory];
    setShowCategories(updatedShowCategories);
    setCampaignData((prevData) => ({
      ...prevData,
      selectedCategories: [...prevData.selectedCategories, prevData.newCategory],
      newCategory: '',
    }));
    setOpenDialog(false);
  };

  const categoriesTheme = createTheme({
    components: {
      MuiChip: {
        styleOverrides: {
          label: { maxWidth: '60px', paddingLeft: '8px', paddingRight: '8px' }
        }
      }
    }
  });

  useEffect(() => {
    const selected = [...(campaign?.categoryTypes || []), ...(campaign?.customCategory || [])];
    const filteredSelected = selected.filter(category => category.trim() !== "");
    setCampaignData((prevData) => ({
      ...prevData,
      selectedCategories: filteredSelected,
    }));
  }, [campaign]);

  useEffect(() => {
    setCampaignData((prevData) => ({
      ...prevData,
      customCategory: prevData.selectedCategories.filter(category => !categories.includes(category)),
    }));
  }, [categories, campaignData.selectedCategories]);

  useEffect(() => {
    if (isFormDirty) {
      const handleBeforeUnload = (e) => {
        const currentPath = window.location.pathname;
        const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
        if (isFormDirty && monitoredPaths.includes(currentPath)) {
          const message = 'You have unsaved changes. Are you sure you want to leave?';
          e.returnValue = message;
          return message;
        }
      };

      const handleNavClick = (e) => {
        if (isFormDirty) {
          const message = 'You have unsaved changes. Are you sure you want to leave?';
          if (!window.confirm(message)) {
            e.preventDefault();
            e.stopImmediatePropagation();
          } else {
            setIsFormDirty(false);
          }
        }
      };

      const handlePopState = (e) => {
        const currentPath = window.location.pathname;
        const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
        if (isFormDirty && monitoredPaths.includes(currentPath)) {
          const message = 'You have unsaved changes. Are you sure you want to leave?';
          const userConfirmed = window.confirm(message);
          if (!userConfirmed) {
            window.history.pushState(null, '', window.location.pathname);
          } else {
            setIsFormDirty(false);
          }
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('popstate', handlePopState);
      window.history.pushState(null, '', window.location.pathname);

      const navLinks = document.querySelectorAll('.nav-link');
      navLinks.forEach((link) => {
        link.addEventListener('click', handleNavClick);
      });

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('popstate', handlePopState);
        navLinks.forEach((link) => {
          link.removeEventListener('click', handleNavClick);
        });
      };
    }
  }, [isFormDirty]);

  const ageRanges = ["16 - 25 years", "25 - 35 years", "35 - 45 years", "45 - 55 years", "55+ years"];

  return (
    <>
      {!loggedUser ?
        <>
        </>
        :
        <div className="campaign-page">
          {enterpriseClient &&
            <Alert severity="info">{`You are creating Campaign on behalf of Enterprise Client ${userName}`}</Alert>
          }
          <h1>{campaign ? "Update Campaign" : "Create Campaign"}</h1>
          <div className="add-campaign-name">
            <TextField sx={campaignNameText} name="campaignName" value={campaignData.campaignName}
              className="textfield" placeholder="Name your campaign" onChange={handleChange} inputProps={{ maxLength: 50 }} />
          </div>
          <span>
            <div className="socialmedia-btn dark"> <InstagramIcon sx={{ fontSize: 20 }} /> <label>Instagram</label> </div>
          </span>
          <p className="highlight-note">About Campaign</p>
          <div className="description-outer-box">
            <ReactQuill
              theme="snow" onKeyDown={checkCharacterCount} ref={reactQuillRef} value={campaignData.description} onChange={(value) => handleChange(null, value, 'description')} name="description" placeholder="Description.."
              className="campaign-description"
              modules={{
                toolbar: [
                  [{ 'header': [] }, { 'font': [] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ color: [] }, { background: [] }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], ['link', 'image', 'video']
                ],
              }}
              formats={[
                'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent', 'link', 'image', 'video', 'color', 'background'
              ]}
            />
          </div>
          <div className="campaign-row">
            <div className="category-type">
              <p className="category-type-label highlight-note">Target Types<span className="asterisk">*</span></p>
              <ThemeProvider theme={categoriesTheme}>
                <Autocomplete
                  className='campaign-categories-dropdown'
                  value={campaignData.selectedCategories}
                  multiple
                  limitTags={3}
                  id="categories"
                  name="selectedCategories"
                  options={[...showCategories.filter(category => category.trim() !== ""), "Others"]}
                  disableCloseOnSelect
                  getOptionLabel={(option) => { if (Array.isArray(option) && option.length === 0) { return ''; } return option; }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (<TextField {...params} placeholder="Target Types" />)}
                  onChange={(event, value) => handleChange(event, value, 'selectedCategories')}
                  renderTags={(value, getTagProps) => (
                    <>
                      {value.filter(tag => tag !== "").slice(0, 3).map((option, index) => (
                        <Chip key={index} title={option.replace(/"/g, '')} label={option.replace(/"/g, '')} {...getTagProps({ index })} />
                      ))}
                      {value.length > 3 && (
                        <Chip label={`${value.length - 3}+`} {...getTagProps({ index: value.length - 1 })} />
                      )}
                    </>
                  )}
                  PaperComponent={({ children }) => (
                    <Paper style={{ maxHeight: '300px', overflowY: 'auto' }}> {children} </Paper>
                  )}
                />
                <Dialog open={openDialog} onClose={handleDialogClose}>
                  <DialogTitle>Add New Category</DialogTitle>
                  <DialogContent>
                    <DialogContentText>Please enter your new category:</DialogContentText>
                    <TextField autoFocus margin="dense" name="newCategory" label="New Category" fullWidth
                      value={campaignData.newCategory} onChange={handleChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleNewCategorySubmit} disabled={!campaignData.newCategory}>Add</Button>
                  </DialogActions>
                </Dialog>
              </ThemeProvider>
            </div>
            <div className="campaign-city">
              <p className="highlight-note">Select City<span className="asterisk">*</span></p>
              <Box>
                <ThemeProvider theme={categoriesTheme}>
                  <CampaignLocation handleChange={handleChange} locationDescription={campaignData.campaignLocations} />
                </ThemeProvider>
              </Box>
            </div>
          </div>
          <div className="campaign-row">
            <div>
              <p className="highlight-note">Number of Creators</p>
              <TextField
                type="number"
                name="creators"
                sx={{ width: '500px' }}
                value={campaignData.creators}
                onChange={handleChange}
                inputProps={{ min: 1 }}
              />
            </div>
            {loggedUser.role === 'ADMIN'
              ? <div>
                <p className="category-type-label highlight-note">Select Enterprise Client<span className="asterisk">*</span></p>
                <ThemeProvider theme={categoriesTheme}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="selectedEC"
                    options={enterpriseClients}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    value={selectedEC}
                    sx={{ width: 500 }}
                    onChange={(event, value) => handleChange(event, value, 'selectedEC')}
                    renderInput={(params) => <TextField {...params} label="Enterprise Client" />}
                  />
                </ThemeProvider>
              </div>
              : <div>
                <p className="category-type-label highlight-note">Brand Handle<span className="asterisk">*</span></p>
                <TextField sx={{ width: 500 }} name="brandHandle"
                  id="outlined-basic" label="Brand Handle" variant="outlined"
                  value={campaignData.brandHandle} onChange={handleChange}
                  error={error}
                  helperText={error ? "Only letters, numbers, dots, and underscores are allowed" : ""}
                />
              </div>
            }
          </div>
          <div className="campaign-row">
            <div className="category-type">
              <p className="category-type-label highlight-note">Select Languages<span className="asterisk">*</span></p>
              <ThemeProvider theme={categoriesTheme}>
                <Autocomplete
                  className="campaign-categories-dropdown"
                  value={campaignData.languages}
                  multiple
                  limitTags={3}
                  id="languages"
                  name="languages"
                  options={languages}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.language}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <span>{option.language}</span>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                      </Box>
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => <TextField {...params} placeholder="Select up to 3 languages" />}
                  onChange={(event, value) => {
                    if (value.length <= 3) {
                      handleChange(event, value, "languages");
                    }
                  }}
                  renderTags={(value, getTagProps) => (
                    <>
                      {value.map((option, index) => (
                        <Chip key={index} title={option.language} label={option.language} {...getTagProps({ index })} />
                      ))}
                    </>
                  )}
                  PaperComponent={({ children }) => (
                    <Paper style={{ maxHeight: "300px", overflowY: "auto" }}>{children}</Paper>
                  )}
                />
              </ThemeProvider>
            </div>
            {loggedUser.role === 'ADMIN' &&
              <div>
                <p className="category-type-label highlight-note">Brand Handle<span className="asterisk">*</span></p>
                <TextField sx={{ width: 500 }} name="brandHandle"
                  id="outlined-basic" label="Brand Handle" variant="outlined"
                  value={campaignData.brandHandle} onChange={handleChange}
                  error={error}
                  helperText={error ? "Only letters, numbers, dots, and underscores are allowed" : ""}
                />
              </div>
            }
          </div>
          <div className="campaign-row">
            <div className="category-type">
              <p className="category-type-label highlight-note">Select Gender<span className="asterisk">*</span></p>
              <ThemeProvider theme={categoriesTheme}>
                <Autocomplete
                  className="campaign-categories-dropdown"
                  value={campaignData.gender}
                  multiple
                  limitTags={3}
                  id="gender"
                  name="gender"
                  options={["Male", "Female", "Any"]}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <span>{option}</span>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                      </Box>
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => <TextField {...params} placeholder="Select Gender" />}
                  onChange={(event, value) => handleChange(event, value, "gender")}
                  renderTags={(value, getTagProps) => (
                    <>
                      {value.slice(0, 3).map((option, index) => (
                        <Chip key={index} title={option} label={option} {...getTagProps({ index })} />
                      ))}
                      {value.length > 3 && (
                        <Chip label={`${value.length - 3}+`} {...getTagProps({ index: value.length - 1 })} />
                      )}
                    </>
                  )}
                  PaperComponent={({ children }) => (
                    <Paper style={{ maxHeight: "300px", overflowY: "auto" }}>{children}</Paper>
                  )}
                />
              </ThemeProvider>
            </div>
            <div className="category-type">
              <p className="category-type-label highlight-note">Campaign Category Type<span className="asterisk">*</span></p>
              <ThemeProvider theme={categoriesTheme}>
                <Autocomplete
                  className="campaign-categories-dropdown"
                  value={campaignData.campaignCategoryType || null}
                  id="campaignCategoryType"
                  name="campaignCategoryType"
                  options={campaignCategoryType || []}
                  getOptionLabel={(option) => option?.campaignCategoryType || ""}
                  onChange={(event, value) => handleChange(event, value, "campaignCategoryType")}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{option.campaignCategoryType}</li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => <TextField {...params} placeholder="Campaign Category Type" />}
                  PaperComponent={({ children }) => (
                    <Paper style={{ maxHeight: "300px", overflowY: "auto" }}>{children}</Paper>
                  )}
                />
              </ThemeProvider>
            </div>
          </div>

          <div className="campaign-row">
            <div className="category-type">
              <p className="category-type-label highlight-note">Age Range<span className="asterisk">*</span></p>
              <ThemeProvider theme={categoriesTheme}>
                <Autocomplete
                  className="campaign-categories-dropdown"
                  value={campaignData.ageRange}
                  multiple
                  id="ageRange"
                  name="ageRange"
                  options={ageRanges}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <span>{option}</span>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                      </Box>
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => <TextField {...params} placeholder="Select Age Range" />}
                  onChange={(event, value) => handleChange(event, value, "ageRange")}
                  renderTags={(value, getTagProps) => (
                    <>
                      {value.slice(0, 3).map((option, index) => (
                        <Chip key={index} title={option} label={option} {...getTagProps({ index })} />
                      ))}
                      {value.length > 3 && (
                        <Chip label={`${value.length - 3}+`} {...getTagProps({ index: value.length - 1 })} />
                      )}
                    </>
                  )}
                  PaperComponent={({ children }) => (
                    <Paper style={{ maxHeight: "300px", overflowY: "auto" }}>{children}</Paper>
                  )}
                />
              </ThemeProvider>
            </div>
          </div>

          <div className="project-type-row">
            <p className="highlight-note">Project Type <span className="asterisk">*</span></p>
            <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }} name="projectType" value={campaignData.projectType} onChange={handleChange}>
              <FormControlLabel value="Reel" control={<Radio />} label="Reel" />
              <FormControlLabel sx={{ margin: '0 3em 0 2em' }} value="Contest" control={<Radio />} label="Contest" />
              <FormControlLabel sx={{ margin: '0 3em 0 2em' }} value="Story" control={<Radio />} label="Story" />
              <FormControlLabel value="Barter" control={<Radio />} label="Barter" />
            </RadioGroup>
          </div>

          <div className="project-type-row">
            <p className="highlight-note">Shoot Location Type <span className="asterisk">*</span></p>
            <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }} name="shootLocationType" value={campaignData.shootLocationType} onChange={handleChange}>
              <FormControlLabel value="On Field" control={<Radio />} label="On Field" />
              <FormControlLabel sx={{ margin: '0 3em 0 2em' }} value="On Site" control={<Radio />} label="On Site" />
              <FormControlLabel value="At Home" control={<Radio />} label="At Home" />
            </RadioGroup>
          </div>

          <div className="start-date-container">
            <p className="start-date-label">
              Select a last date for the content collection
              <div className="information-badge">i
                <span className="tooltip">Please specify the start date of the campaign. We will start tracking creators' content from that day forward.</span>
              </div>
            </p>
            <p className="last-date-application-text">Last date of application</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker name="lastDate"
                value={campaignData.lastDate}
                disablePast={true}
                onChange={(newValue) => handleChange(null, newValue, 'lastDate')}
                format="MMMM DD, YYYY"
                renderInput={(params) => (<TextField {...params} variant="outlined" />)}
              />
            </LocalizationProvider>
          </div>

          <div className="created-by-row">
            <p className="highlight-note">Created By: {campaign && campaign.createdBy != null ? campaign.createdBy.fullName : loggedUser ? `${loggedUser.firstName} ${loggedUser.lastName}` : ""}</p>
          </div>
          <div className="create-campaign-footer">
            <p className="note-for-date">Cannot be modified after campaign launch</p>
            <div className="start-campaign-container">
              {(fromPage === 'details' && (campaign && campaign.status) !== 'Draft') || (campaign && campaign.status) === 'Pending' ? (
                <>
                  {(loggedUser.role === 'ADMIN' && (campaign && campaign.status) === 'Live') || (loggedUser.role === 'ADMIN' && (campaign && campaign.status) === 'Pending') ? (
                    <button className="choose-btn" disabled={!campaignData.campaignName || campaignData.selectedCategories.length === 0 || campaignData.campaignLocations.length === 0 || !campaignData.brandHandle || !selectedEC || error || campaignData.languages.length === 0 || campaignData.gender.length === 0 || !campaignData.projectType || !campaignData.shootLocationType || campaignData.ageRange.length === 0 || !campaignData.campaignCategoryType} onClick={() => handleCampaignAction((campaign && campaign.status) === 'Live' ? 'Live' : 'Pending')}>
                      Update Campaign
                    </button>
                  ) : (
                    <button className="choose-btn" disabled={!campaignData.campaignName || campaignData.selectedCategories.length === 0 || campaignData.campaignLocations.length === 0 || !campaignData.brandHandle || (loggedUser.role === 'ADMIN' && !selectedEC) || error || campaignData.languages.length === 0 || campaignData.gender.length === 0 || !campaignData.projectType || !campaignData.shootLocationType || campaignData.ageRange.length === 0 || !campaignData.campaignCategoryType} onClick={() => handleCampaignAction('Pending')}>
                      Update Campaign
                    </button>
                  )}
                </>
              ) : (
                <>
                  <button className="draft-btn" disabled={!campaignData.campaignName || (loggedUser.role === 'ADMIN' && !selectedEC) || error} onClick={() => handleCampaignAction('Draft')}>
                    Save As Draft
                  </button>
                  <button className="choose-btn" disabled={!campaignData.campaignName || campaignData.selectedCategories.length === 0 || campaignData.campaignLocations.length === 0 || !campaignData.brandHandle || (loggedUser.role === 'ADMIN' && !selectedEC) || error || campaignData.languages.length === 0 || campaignData.gender.length === 0 || !campaignData.projectType || !campaignData.shootLocationType || campaignData.ageRange.length === 0 || !campaignData.campaignCategoryType} onClick={() => handleCampaignAction('Pending')}>
                    Send For Approval
                  </button>
                </>
              )}
            </div>

          </div>
          <div className="alert-msg">
            <CustomAlert open={alertOpen} severity={alertSeverity} message={alertMessage} onClose={() => setAlertOpen(false)} />
          </div>
          <DialogBox userDetails={loggedUser} openAlert={openBillingDetailAlert} setOpenAlert={setOpenBillingDetailAlert} forEC={true} enterpriseClient={true} />
        </div>
      }
    </>
  );
}

export default CreateCampaign;
