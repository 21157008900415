import React, { useEffect, useState } from 'react';
import "../../css/campaign-report/campaign-report.css";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { getAllCampaignReports } from './report-repository';
import AddPostDialog from './add-post-dialog';
import Alert from '../common/alert';

function CampaignReports() {
    const navigate = useNavigate();

    const [campaignReports, setCampaignReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false); 
    const [selectedReportId, setSelectedReportId] = useState(null);
     const [openSnackbar, setOpenSnackbar] = useState(false);
        const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        setLoading(true);
        getAllCampaignReports(setCampaignReports, setLoading);
    }, []);

    const handleRowClick = (reportId) => {
        navigate(`/campaign-report`, { state: { reportId } });
    };
    const handleAddPostClick = (reportId) => {
        setSelectedReportId(reportId);
        setDialogOpen(true);
    };
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    return (
        <>
            <div className='campaign-header'>
                <div className='campaign-title'>Your Campaigns</div>
                <div className='ec-leads-top-filter' style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#65308F',
                            '&:hover': { backgroundColor: '#65308F' },
                            borderRadius: "50px",
                        }}
                        onClick={() => navigate("/new-report")}
                        startIcon={<AddIcon />}
                    >
                        New Report
                    </Button>
                </div>
            </div>

            <div className="table-container">
               
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="campaign reports table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Campaigns</TableCell>
                                    <TableCell align="right">Type</TableCell>
                                    <TableCell align="right">Brand</TableCell>
                                    <TableCell align="right">Story</TableCell>
                                    <TableCell align="right">Reel</TableCell>
                                    <TableCell align="right">Create Date</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) :campaignReports.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            No campaign report found
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    campaignReports.map((report) => (
                                        <TableRow
                                            key={report.reportId} 
                                           
                                        >
                                            <TableCell  onClick={() => handleRowClick(report.reportId)} 
                                            style={{ cursor: 'pointer' }}>{report.campaignName}</TableCell>
                                            <TableCell align="right">{report.type ? report.type : "-"}</TableCell>
                                            <TableCell align="right">{report.brandHandle}</TableCell>
                                            <TableCell align="right">{report.totalStory ? report.totalStory : "-"}</TableCell>
                                            <TableCell align="right">{report.totalReel ? report.totalReel : "-"}</TableCell>
                                            <TableCell align="right">
                                                {new Date(report.creationDate).toLocaleDateString("en-US", {
                                                    month: "short",
                                                    day: "2-digit",
                                                    year: "numeric"
                                                })}
                                            </TableCell>
                                            <TableCell>
                                            <button className="add-post-btn" onClick={() => handleAddPostClick(report.reportId)}>+ Add post</button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
               
            </div>
            <AddPostDialog open={dialogOpen} onClose={() => setDialogOpen(false)} reportId={selectedReportId} setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} loading={loading} setLoading={setLoading} />
            <Alert
                open={openSnackbar}
                severity="success"
                message={snackbarMessage}
                onClose={handleSnackbarClose}
            />
        </>
    );
}

export default CampaignReports;
