import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CommonAppbarDrawer from '../common/common-sidebar-navbar';
import { DrawerHeader } from '../../mui/appbar-style-component';
import { Outlet, useLocation } from 'react-router-dom';

import ReportHeader from './report-header';
import AddPost from './add-post';
import { getCampaignReportPosts } from './report-repository';
import PostFilters from './post-filters';

function CampaignReportDashboard() {
    const location = useLocation();
    const { reportId } = location.state || {}; 

    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (reportId) {
            getCampaignReportPosts(reportId,null, null, setLoading)

                .then((result) => {

                    if (result.success) {
                        setReportData(result.data);
                    } else {
                        alert(result.message);
                    }
                })
                
                .catch(() => {
                    alert("An error occurred while fetching the report posts.");
                });
        }
    }, [reportId]);  

    return (
        <Box sx={{ display: 'flex' }}>
            <CommonAppbarDrawer />
            <Box component="main" sx={{ flexGrow: 1, padding: '58px' }}>
                <DrawerHeader />
                <Outlet />
                <ReportHeader reportId={reportId} reportData={reportData} setReportData={setReportData}  loading={loading} setLoading={setLoading} />
                <PostFilters reportId={reportId} reportData={reportData} setReportData={setReportData} loading={loading} setLoading={setLoading}/>
                <AddPost reportId={reportId} reportData={reportData} setReportData={setReportData} loading={loading} setLoading={setLoading} />
                </Box>
        </Box>
    );
}

export default CampaignReportDashboard;
