import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AppBar, Drawer, DrawerHeader } from '../../mui/appbar-style-component';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Backdrop, Badge, Box, Divider, LinearProgress, Menu, MenuItem, styled, Tooltip, Typography, useMediaQuery } from "@mui/material";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import FeedIcon from '@mui/icons-material/Feed';
import TopicIcon from '@mui/icons-material/Topic';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import MobileFriendlyOutlinedIcon from '@mui/icons-material/MobileFriendlyOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { getUserAssignRole, reloadInstaInsights } from '../common/user-details-repo';
import logo from '../../assets/creator-logo.svg'
import '../../css/dashboard.css';
import { UserDetailsContext } from "../context/user-details-context";
import EditIcon from '@mui/icons-material/Edit';
import CachedIcon from '@mui/icons-material/Cached';
import Logout from '@mui/icons-material/Logout';
import { getConfig } from "./env-config";
import ForceUpdateLocation from "./force-update-location";
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

function CommonAppbarDrawer({ loading, setIsReloadDetails }) {
    const theme = useTheme();
    const { baseUrl } = getConfig();
    const { loggedUser } = useContext(UserDetailsContext);
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(!isMobile);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [selectedSubMenuItem, setSelectedSubMenuItem] = useState('');
    const [reloading, setReloading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showCampaignSubMenu, setShowCampaignSubMenu] = useState(false);
    const [showBillingSubMenu, setShowBillingSubMenu] = useState(false);
    const [showPagesSubMenu, setShowPagesSubMenu] = useState(false);
    const [showCreatorSubMenu, setShowCreatorSubMenu] = useState(false);
    const [showECSubMenu, setShowECSubMenu] = useState(false);
    const [showAvatar, setShowAvatar] = useState(true);
    const [profilePictureUrl, setProfilePictureUrl] = useState('');
    const [openForceUpdate, setForceUpdateOpen] = useState(false);
    const [switchAnchorEl, setSwitchAnchorEl] = useState(null);
    const openSwitchRole = Boolean(switchAnchorEl);
    const [userRoles, setUserRoles] = useState([]);
    const [openLoader, setOpenLoader] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const creatorMenuItems = useMemo(() => [
        { text: 'Dashboard', icon: <PersonOutlineIcon />, path: '/dashboard' },
        { text: 'Campaigns', icon: <CampaignOutlinedIcon />, path: '/all-campaigns/live' },
        { text: 'Earning', icon: <PaymentsOutlinedIcon />, path: '/earning' }
    ], []);

    const adminMenuItems = useMemo(() => [
        { text: 'Admin Dashboard', icon: <PersonOutlineIcon />, path: '/admin-dashboard' },
        {
            text: 'Creator', icon: <GroupOutlinedIcon />, drowpdown: true,
            subMenuItems: [
                {
                    text: 'Creators List', icon: <GroupOutlinedIcon />, onClick: (e) => {
                        e.preventDefault();
                        navigate("/creator/admin-panel/user-list", { state: { enterpriseClient: false } })
                    }
                },
                { text: 'Leads Dashboard', icon: <MobileFriendlyOutlinedIcon />, path: '/creator/leads-dashboard' },
                { text: 'Tags Dashboard', icon: <LocalOfferOutlinedIcon />, path: '/creator/tags-dashboard' },
                { text: 'Users Affiliate List', icon: <HubOutlinedIcon />, path: '/creator/users-affiliate-marketing' }

            ]
        },
        {
            text: 'Enterprise Client', icon: <AccountCircleOutlinedIcon />, drowpdown: true,
            subMenuItems: [
                {
                    text: 'Enterprise Clients', icon: <AccountCircleOutlinedIcon />,
                    onClick: (e) => {
                        e.preventDefault();
                        navigate("/enterprise-client/admin-panel/enterprise-list", { state: { enterpriseClient: true } })
                    }
                },
                {
                    text: 'Create EC', icon: <PersonAddAltIcon />, onClick: (e) => {
                        e.preventDefault();
                        navigate("/enterprise-client/registration", { state: { enterpriseClient: true, adminUpdate: true } })
                    }
                },
                ((loggedUser && loggedUser.access.includes('Lead:View')) || (loggedUser && loggedUser.access.includes('LeadDashboard:View')) ||
                    (loggedUser && loggedUser.access.includes('Lead:Edit')) || (loggedUser && loggedUser.access.includes('Lead:Create'))) &&
                { text: 'Leads Dashboard', icon: <MobileFriendlyOutlinedIcon />, path: '/enterprise-client/ec-leads-dashboard' },
                { text: 'EC Affiliate List', icon: <HubOutlinedIcon />, path: '/enterprise-client/ec-affiliate-marketing' }

            ]
        },
        {
            text: 'Campaigns', icon: <CampaignOutlinedIcon />, drowpdown: true,
            subMenuItems: [
                { text: 'Completed', icon: <CheckCircleOutlinedIcon />, path: '/all-campaigns/completed' },
                { text: 'Live', icon: <AccessTimeIcon />, path: '/all-campaigns/live' },
                { text: 'Pending', icon: <PendingOutlinedIcon />, path: '/all-campaigns/pending' },
                { text: 'Draft', icon: <SaveAsOutlinedIcon />, path: '/all-campaigns/draft' },
                { text: 'Create Campaign', icon: <AddCircleOutlineIcon />, path: '/all-campaigns/create' }
            ]
        },
        {
            text: 'Billing', icon: <ReceiptLongOutlinedIcon />, drowpdown: true,
            subMenuItems: [
                { text: 'Completed', icon: <CheckCircleOutlinedIcon />, path: '/billing/completed' },
                { text: 'Pending', icon: <PendingOutlinedIcon />, path: '/billing/pending' },
                { text: 'Draft', icon: <SaveAsOutlinedIcon />, path: '/billing/draft' },
            ]
        },
        {
            text: 'Pages', icon: <ArticleOutlinedIcon />, drowpdown: true, drowpdownIcon: <ArrowDropDownOutlinedIcon />,
            subMenuItems: [
                { text: 'Case Studies', icon: <TopicIcon />, path: '/pages/case-studies' },
                { text: 'Blogs', icon: <FeedIcon />, path: '/pages/blogs' },
                { text: 'Career', icon: <QueryStatsIcon />, path: '/pages/career' },
            ]
        },
        { text: 'Tickets', icon: <LocalActivityOutlinedIcon />, path: '/tickets' },
        { text: 'Report', icon: <SummarizeOutlinedIcon />, path: '/campaign-report-list' },

    ], [navigate, loggedUser]);

    const leadAgentAndManagerMenuItems = useMemo(() => [
        { text: 'Leads Dashboard', icon: <MobileFriendlyOutlinedIcon />, path: '/enterprise-client/ec-leads-dashboard' }
    ], []);

    const enterpriseClientItems = useMemo(() => [
        {
            text: 'Create Campaign', icon: <AddCircleOutlineIcon />, onClick: (e) => {
                e.preventDefault();
                navigate("/my-campaigns/create", { state: { userId: (loggedUser && loggedUser.userId) } })
            }
        },
        { text: 'Live Campaign', icon: <ToggleOnOutlinedIcon />, path: '/my-campaigns/live' },
        { text: 'Pending Campaign', icon: <PendingOutlinedIcon />, path: '/my-campaigns/pending' },
        { text: 'Draft Campaign', icon: <SaveAsOutlinedIcon />, path: '/my-campaigns/draft' },
        { text: 'Completed Campaign', icon: <CheckCircleOutlinedIcon />, path: '/my-campaigns/completed' },
        {
            text: 'Billing', icon: <ReceiptLongOutlinedIcon />, drowpdown: true,
            subMenuItems: [
                { text: 'Pending', icon: <AccessTimeIcon />, path: '/billing/pending' },
                { text: 'Paid', icon: <CurrencyRupeeIcon />, path: '/billing/paid' },
            ]
        },
        { text: 'Campaign Statistics', icon: <TrendingUpIcon />, path: '/campaign-statistics' },
        { text: 'Discovery', icon: <PersonSearchIcon />, path: '/discovery' }
    ], [navigate, loggedUser]);

    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname.includes('/dashboard')) {
            setSelectedMenuItem('Dashboard');
        } else if (pathname.includes('/all-campaigns/completed')) {
            setSelectedMenuItem('Campaigns');
            setSelectedSubMenuItem('Completed');
            setShowCampaignSubMenu(true);
        } else if (pathname.includes('/all-campaigns/live')) {
            setSelectedMenuItem('Campaigns');
            setSelectedSubMenuItem('Live');
            setShowCampaignSubMenu(true);
        } else if (pathname.includes('/all-campaigns/pending')) {
            setSelectedMenuItem('Campaigns');
            setSelectedSubMenuItem('Pending');
            setShowCampaignSubMenu(true);
        } else if (pathname.includes('/all-campaigns/draft')) {
            setSelectedMenuItem('Campaigns');
            setSelectedSubMenuItem('Draft');
            setShowCampaignSubMenu(true);
        } else if (pathname.includes('/all-campaigns/create')) {
            setSelectedMenuItem('Campaigns');
            setSelectedSubMenuItem('Create Campaign');
            setShowCampaignSubMenu(true);
        } else if (pathname === '/my-campaigns/create') {
            setSelectedMenuItem('Create Campaign');
        } else if (pathname === '/my-campaigns/live') {
            setSelectedMenuItem('Live Campaign');
        } else if (pathname === '/my-campaigns/pending') {
            setSelectedMenuItem('Pending Campaign');
        } else if (pathname === '/my-campaigns/draft') {
            setSelectedMenuItem('Draft Campaign');
        } else if (pathname === '/my-campaigns/completed') {
            setSelectedMenuItem('Completed Campaign');
        } else if (pathname.includes('/earning')) {
            setSelectedMenuItem('Earning');
        } else if (pathname.includes('/creators-list')) {
            setSelectedMenuItem('Creators');
        } else if (pathname.includes('/discovery')) {
            setSelectedMenuItem('Discovery');
        } else if (pathname.includes('/creator/admin-panel/user-list')) {
            setSelectedMenuItem('Creator');
            setSelectedSubMenuItem('Creators List');
            setShowCreatorSubMenu(true);
        } else if (pathname.includes('/enterprise-client/admin-panel/enterprise-list')) {
            setSelectedMenuItem('Enterprise Client');
            setSelectedSubMenuItem('Enterprise Clients');
            setShowECSubMenu(true);
        } else if (pathname.includes('/enterprise-client/registration')) {
            setSelectedMenuItem('Enterprise Client');
            setSelectedSubMenuItem('Create EC');
            setShowECSubMenu(true);
        } else if (pathname.includes('/billing/completed')) {
            setSelectedMenuItem('Billing');
            setSelectedSubMenuItem('Completed');
            setShowBillingSubMenu(true);
        } else if (pathname.includes('/billing/pending')) {
            setSelectedMenuItem('Billing');
            setSelectedSubMenuItem('Pending');
            setShowBillingSubMenu(true);
        } else if (pathname.includes('/billing/draft')) {
            setSelectedMenuItem('Billing');
            setSelectedSubMenuItem('Draft');
            setShowBillingSubMenu(true);
        } else if (pathname.includes('/billing/paid')) {
            setSelectedMenuItem('Billing');
            setSelectedSubMenuItem('Paid');
            setShowBillingSubMenu(true);
        } else if (pathname.includes('/pages/case-studies')) {
            setSelectedMenuItem('Pages');
            setSelectedSubMenuItem('Case Studies');
            setShowPagesSubMenu(true);
        } else if (pathname.includes('/pages/blogs')) {
            setSelectedMenuItem('Pages');
            setSelectedSubMenuItem('Blogs');
            setShowPagesSubMenu(true);
        } else if (pathname.includes('/pages/career')) {
            setSelectedMenuItem('Pages');
            setSelectedSubMenuItem('Career');
            setShowPagesSubMenu(true);
        } else if (pathname.includes('/tickets')) {
            setSelectedMenuItem('Tickets');
        } else if (pathname.includes('/campaign-report-list')) {
            setSelectedMenuItem('Report');
        } else if (pathname.includes('/campaign-statistics')) {
            setSelectedMenuItem('Campaign Statistics');
        } else if (pathname.includes('/creator/leads-dashboard')) {
            setSelectedMenuItem('Creator');
            setSelectedSubMenuItem('Leads Dashboard');
            setShowCreatorSubMenu(true);
        } else if (pathname.includes('/creator/tags-dashboard')) {
            setSelectedMenuItem('Creator');
            setSelectedSubMenuItem('Tags Dashboard');
            setShowCreatorSubMenu(true);
        } else if (pathname.includes('/admin-dashboard')) {
            setSelectedMenuItem('Admin Dashboard');
        } else if (pathname.includes('/enterprise-client/ec-leads-dashboard')) {
            if (loggedUser && (loggedUser.role === 'LEAD_AGENT' || loggedUser.role === 'LEAD_MANAGER')) {
                setSelectedMenuItem('Leads Dashboard');
            } else {
                setSelectedMenuItem('Enterprise Client');
                setSelectedSubMenuItem('Leads Dashboard');
                setShowECSubMenu(true);
            }
        } else if (pathname.includes('/enterprise-client/create-internal-ec-lead')) {
            if (loggedUser && (loggedUser.role === 'LEAD_AGENT' || loggedUser.role === 'LEAD_MANAGER')) {
                setSelectedMenuItem('Leads Dashboard');
            } else {
                setSelectedMenuItem('Enterprise Client');
            }
        } else if (pathname.includes('/creator/users-affiliate-marketing')) {
            setSelectedMenuItem('Creator');
            setSelectedSubMenuItem('Users Affiliate List');
            setShowCreatorSubMenu(true);
        } else if (pathname.includes('/enterprise-client/ec-affiliate-marketing')) {
            setSelectedMenuItem('Enterprise Client');
            setSelectedSubMenuItem('EC Affiliate List');
            setShowECSubMenu(true);
        }
    }, [enterpriseClientItems, adminMenuItems, creatorMenuItems, loggedUser]);

    const handleReload = async () => {
        setReloading(true);
        await reloadInstaInsights(loggedUser.instaHandle);
        if (setIsReloadDetails != null) {
            setIsReloadDetails(Math.random().toString(36).substring(7));
        }
        const timestamp = new Date().getTime();
        const downloadUrl = `${baseUrl}/download-insta-profile/${loggedUser.instaHandle + "_profile"}?t=${timestamp}`;
        setProfilePictureUrl(downloadUrl);
        setReloading(false);
    };

    const logout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshTokenExpired");
        localStorage.removeItem("phoneNumber");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("profileExist");
        localStorage.removeItem("role");
        localStorage.removeItem("access");
        localStorage.removeItem("userId");
        navigate("/signin");
    }

    const editProfile = () => {
        navigate("/login", { state: { userDetails: loggedUser, enterpriseClient: loggedUser.role === 'ENTERPRISE_CLIENT' } });
    }

    useEffect(() => {
        if (loggedUser) {
            getUserAssignRole(setUserRoles);
            setProfilePictureUrl(`${baseUrl}/download-insta-profile/${loggedUser.instaHandle + "_profile"}?t=${new Date().getTime()}`);
            if ((loggedUser.userLocation === null || !loggedUser.locality) && loggedUser.role === 'CREATOR') {
                setForceUpdateOpen(true);
            } else {
                setForceUpdateOpen(false);
            }
        }
    }, [loggedUser, baseUrl]);

    const handleOption = (e, menuText) => {
        e.preventDefault();
        if (showCampaignSubMenu || showBillingSubMenu || showPagesSubMenu || showCreatorSubMenu || showECSubMenu) {
            setShowCampaignSubMenu(false);
            setShowBillingSubMenu(false);
            setShowPagesSubMenu(false);
            setShowCreatorSubMenu(false);
            setShowECSubMenu(false);
        } else {
            if (menuText === 'Billing') {
                setShowBillingSubMenu(true);
            } else if (menuText === 'Pages') {
                setShowPagesSubMenu(true);
            } else if (menuText === 'Campaigns') {
                setShowCampaignSubMenu(true);
            } else if (menuText === 'Creator') {
                setShowCreatorSubMenu(true);
            } else if (menuText === 'Enterprise Client') {
                setShowECSubMenu(true);
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setShowAvatar(false);
            } else {
                setShowAvatar(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSwitchRoleClick = (event) => {
        setSwitchAnchorEl(event.currentTarget);
    };

    const handleSwitchRoleClose = () => {
        setSwitchAnchorEl(null);
    };

    const handleSwitchAccount = async (userRole, phoneNumber) => {
        try {
            handleSwitchRoleClose();
            handleClose();
            handleOpenLoader();
            const response = await fetch(`${baseUrl}/user/get-role-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`
                },
                body: JSON.stringify({
                    phoneNumber: phoneNumber,
                    selectedRole: userRole
                })
            });

            const data = await response.json();
            if (response.ok) {
                localStorage.setItem('phoneNumber', phoneNumber);
                localStorage.setItem("accessToken", data.accessToken);
                localStorage.setItem("refreshToken", data.refreshToken);
                localStorage.setItem("profileExist", data.profileExist);
                localStorage.setItem("role", data.role);
                localStorage.setItem("access", data.access);

                if (data.role === 'ADMIN') {
                    window.location.href = "/admin-dashboard";
                } else if (data.role === 'CREATOR') {
                    window.location.href = "/dashboard";
                } else if (data.role === 'ENTERPRISE_CLIENT') {
                    window.location.href = "/discovery";
                } else if (data.role === 'LEAD_AGENT') {
                    window.location.href = "/enterprise-client/ec-leads-dashboard";
                } else if (data.role === 'LEAD_MANAGER') {
                    window.location.href = "/enterprise-client/ec-leads-dashboard";
                } else {
                    window.location.href = "/error";
                }
            } else {
                console.error('Error occure switching role: ', data.message);
            }
        } catch (error) {
            console.error('Error occure switching role: ', error);
        }
    }

    const handleLoaderClose = () => {
        setOpenLoader(false);
    };

    const handleOpenLoader = () => {
        setOpenLoader(true);
    };

    function formatRole(role) {
        return role
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase());
    }

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    return (
        <>
            <CssBaseline />
            <AppBar open={open} sx={{ paddingRight: '0px !important' }}>
                <Toolbar sx={{ justifyContent: open ? 'flex-end' : 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Account settings">
                            <IconButton className="nav-link" onClick={handleClick} sx={{ p: 0, display: showAvatar ? 'block' : 'none' }}>
                                {loggedUser && loggedUser.firstName && loggedUser.lastName && loggedUser.instaInsight == null ? (
                                    <Avatar sx={{ width: 45, height: 45, backgroundColor: '#d8cce6', color: 'black', fontSize: '1.2rem' }}>
                                        {loggedUser.firstName.charAt(0) + loggedUser.lastName.charAt(0)}
                                    </Avatar>
                                ) : (
                                    <div className="influencers-profile-container avatar-box" style={{
                                        backgroundImage: `url(${profilePictureUrl})`
                                    }}>
                                    </div>
                                )}
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {userRoles.length > 1 && (
                                <>
                                    <MenuItem onClick={handleSwitchRoleClick}>
                                        <ListItemIcon>
                                            <StyledBadge overlap="circular" variant="dot"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
                                                <Avatar alt="profile-pic" sx={{ width: 33, height: 33, fontSize: '1rem' }}
                                                    src={loggedUser && loggedUser.firstName && loggedUser.lastName &&
                                                        loggedUser.profilePictureId != null && profilePictureUrl
                                                    }
                                                >
                                                    {loggedUser && loggedUser.firstName && loggedUser.lastName &&
                                                        loggedUser.profilePictureId == null &&
                                                        loggedUser.firstName.charAt(0) + loggedUser.lastName.charAt(0)}
                                                </Avatar>
                                            </StyledBadge>
                                        </ListItemIcon>
                                        <ListItemText style={{ paddingLeft: '10px' }}>{formatRole(loggedUser && loggedUser.role)}</ListItemText>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            <ArrowForwardIosIcon sx={{ fontSize: "1rem" }} />
                                        </Typography>
                                    </MenuItem>
                                    <Menu id="basic-menu" anchorEl={switchAnchorEl} open={openSwitchRole}
                                        onClose={handleSwitchRoleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        sx={{ mt: 1 }}
                                    >
                                        {userRoles.map((userrole) => (
                                            <MenuItem key={userrole} onClick={() => handleSwitchAccount(userrole, loggedUser.phoneNumber)}
                                                sx={{
                                                    backgroundColor:
                                                        userrole === formatRole(loggedUser && loggedUser.role)
                                                            ? '#0000000a'
                                                            : 'inherit',
                                                }}
                                            >
                                                {userrole}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                    <Divider sx={{ borderColor: '#00000045' }} />
                                </>
                            )}
                            <MenuItem onClick={editProfile}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                Edit Profile
                            </MenuItem>
                            <MenuItem onClick={handleReload}>
                                <ListItemIcon>
                                    {reloading ? (
                                        <CachedIcon fontSize="small" className="rotating" />
                                    ) : (
                                        <CachedIcon fontSize="small" />
                                    )}
                                </ListItemIcon>
                                Reload Insta Insights
                            </MenuItem>
                            <MenuItem onClick={logout}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
                {(loading || !loggedUser) &&
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader style={{ backgroundColor: '#5d4980' }}>
                    <div className="drawer-header">
                        {open && <img src={logo} alt="logo" className="app-logo" />}
                        <IconButton onClick={handleDrawerClose} sx={{ ...(!open && { display: 'none' }) }}>
                            {theme.direction === 'rtl' ? <KeyboardDoubleArrowRightIcon sx={{ color: 'white' }} /> : <KeyboardDoubleArrowLeftIcon sx={{ color: 'white' }} />}
                        </IconButton>
                    </div>
                </DrawerHeader>
                {loggedUser &&
                    <List sx={{ paddingTop: '30px', paddingLeft: '6px', paddingRight: '6px' }} className="nav-link">
                        {(loggedUser.role === 'ADMIN' ? adminMenuItems : loggedUser.role === 'CREATOR'
                            ? creatorMenuItems : (loggedUser.role === 'LEAD_AGENT' || loggedUser.role === 'LEAD_MANAGER') ? leadAgentAndManagerMenuItems : enterpriseClientItems).map((menu, index) => (
                                <ListItem key={menu.text} disablePadding sx={{ display: 'block' }} >
                                    <ListItemButton
                                        onClick={menu.subMenuItems ? (e) => handleOption(e, menu.text) : menu.onClick}
                                        component={Link}
                                        to={menu.path}
                                        selected={selectedMenuItem === menu.text}
                                        className={selectedMenuItem === menu.text ? "selectedMenuItem" : ""}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            padding: '8px 15px',
                                            borderRadius: selectedMenuItem === menu.text ? '11px' : 'none',
                                            ...(selectedMenuItem === menu.text && {
                                                backgroundColor: '#f0f0f0',
                                            })
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {menu.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={
                                            <>
                                                {menu.text}
                                                {menu.dropdown && <span>&#11167;</span>}
                                            </>
                                        } sx={{
                                            opacity: open ? 1 : 0,
                                            ...(selectedMenuItem === menu.text && {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            })
                                        }} />

                                        {menu.subMenuItems && open && (((showCampaignSubMenu && menu.text === 'Campaigns') || (showBillingSubMenu && menu.text === 'Billing') ||
                                            (showPagesSubMenu && menu.text === 'Pages') || (showCreatorSubMenu && menu.text === 'Creator') || (showECSubMenu && menu.text === 'Enterprise Client'))
                                            ? <ExpandLess /> : <ExpandMore />)}
                                    </ListItemButton>

                                    <Collapse in={menu.subMenuItems
                                        && ((showCampaignSubMenu && menu.text === 'Campaigns') || (showBillingSubMenu && menu.text === 'Billing') ||
                                            (showPagesSubMenu && menu.text === 'Pages') || (showCreatorSubMenu && menu.text === 'Creator') ||
                                            (showECSubMenu && menu.text === 'Enterprise Client'))} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {menu.subMenuItems && menu.subMenuItems.map((menuItem, index) => (
                                                <ListItemButton onClick={menuItem.onClick}
                                                    component={Link}
                                                    to={menuItem.path}
                                                    sx={{ pl: open ? 6 : 2, color: selectedSubMenuItem === menuItem.text ? "#e563a4" : "", '&:hover': { color: '#e563a4' } }}>
                                                    <ListItemIcon sx={{ minWidth: '32px' }}>
                                                        {menuItem.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={open && menuItem.text} primaryTypographyProps={{ variant: 'body2', sx: { fontSize: '0.875rem' } }} />
                                                </ListItemButton>
                                            ))
                                            }
                                        </List>
                                    </Collapse>
                                </ListItem>
                            ))}
                    </List >
                }
                <List style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} className="nav-link">
                    <ListItem disablePadding sx={{ padding: '10px 20px', backgroundColor: '#5d4980', color: 'white', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>
                            <ConfirmationNumberOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Raise a ticket" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItem>
                    <ListItem component={Link} to={'https://wa.me/+918484965525'} target="_blank"
                        rel="noopener noreferrer" disablePadding sx={{ padding: '10px 20px', backgroundColor: '#5d4980', color: 'white', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>
                            <WhatsAppIcon sx={{ backgroundColor: 'green' }} />
                        </ListItemIcon>
                        <ListItemText primary="WhatsApp Support" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItem>
                </List>
            </Drawer>
            <ForceUpdateLocation openForceUpdate={openForceUpdate} loggedUser={loggedUser} />
            <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={openLoader} onClick={handleLoaderClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default CommonAppbarDrawer;
