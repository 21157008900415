export function formatPostDate(postTime) {
    const date = new Date(postTime);

    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    return date.toLocaleString('en-US', options).replace(',', '');
}

export const formatPostDateWithoutTime = (date) => {
    if (!date) return "-";

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
};

export const formatNumber = (num) => {
    if (isNaN(num) || num === null || num === undefined) return "-";

    const number = Number(num);

    if (number >= 1000000) return (number / 1000000).toFixed(2) + "M";
    if (number >= 1000) return (number / 1000).toFixed(2) + "K";

    return number % 1 === 0 ? number.toString() : number.toFixed(2);
};

export const formatPercentage = (num) => num.toFixed(2) + "%";
